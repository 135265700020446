<template>
  <div>
    <div v-if="model===false">
      <div class="screen">
        <div style="height:75%;display: flex;justify-content: space-between;padding:3.023609394314vh 8vw 3.023609394314vh 8vw ;">
          <div class="left">
<!--            <div class="left3" style="margin-left: 0">-->
<!--              <span style="cursor: pointer" @click="gptsProfessional()">油气专业GPTs</span><br>-->
<!--              <div @click="gptsDesign()" class="community">工程方案设计GPTs</div>-->
<!--              <div @click="gptsTranslate()" class="community">解释类GPTs</div>-->
<!--              <div @click="gptsTechnology()" class="community">生产工艺优化GPTs</div>-->
<!--            </div>-->
            <div class="left1">
              <span style="cursor: pointer" @click="gpt3">油气通GPT社区</span><br>
              <div @click="gpts1()" class="community">油气通AI技术研讨专区</div>
              <div @click="gpts2()" class="community">油气通大模型知识库</div>
            </div>
            <div class="left2">
              <span>联系我们</span><br>
              <div class="community1">联系我们：<span class="community" @click="dialogVisible = true">产品咨询</span></div>
              <div class="community1"  >联系邮箱：<a class="community" href="mailto:anvision@antonoil.com" style="border-bottom: gray 1px solid">anvision@antonoil.com</a>
                <div class="community1">联系电话：<span class="community" style="margin-left: 0px">+86&nbsp;&nbsp;17521198294</span></div>
                <div class="community1">联系地址：<span class="community" style="margin-left: 0px" >北京市朝阳区东湖渠屏翠西路8号</span></div>

              </div>
            </div>
          </div>
          <div class="left">
            <div class="left2">
              <span style=" color:#FFFFFF;"></span><br>
              <div class="up">
                <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                  <img loading="lazy" @click="showModalTrue(require('@/assets/attention.png'))"   src="@/assets/attention.png">

                </el-tooltip>
              </div>
              <div class="down">扫一扫<br>进入移动端</div>
            </div>
          </div>
          <div class="new" >
            <div class="title" style="text-align: center">关注我们</div>
            <div class="right">
              <div class="left2">
                <div class="up">
                  <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                    <img loading="lazy" @click="showModalTrue(require('@/assets/gongzhonghao.jpg'))" src="@/assets/gongzhonghao.jpg">

                  </el-tooltip>
                </div>
                <div class="down">公众号</div>
              </div>

              <div class="left2">
                <div class="up">
                  <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                    <img loading="lazy" @click="showModalTrue(require('@/assets/jinritoutiao.png'))"  src="@/assets/jinritoutiao.png">

                  </el-tooltip>
                </div>
                <div class="down">今日头条</div>
              </div>
              <div class="left2">
                <div class="up">
                  <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                    <img loading="lazy" @click="showModalTrue(require('@/assets/zhihu.png'))"   src="@/assets/zhihu.png">

                  </el-tooltip>
                </div>
                <div class="down"> 知乎</div>
              </div>
              <div class="left2">
                <div class="up">
                  <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                    <img loading="lazy" @click="showModalTrue(require('@/assets/douyin.png'))" src="@/assets/douyin.png">

                  </el-tooltip>
                </div>
                <div class="down">抖音</div>
              </div>
            </div>

          </div>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content" >
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <div class="foot">
          <div class="flex">
            <p>北京市怀柔区迎宾南路11号</p>
            <span> | </span>
            <p>北京安迅由气通智能技术有限公司版权所有</p>
            <span> | </span>
            <p class="red">
              <a href="https://beian.miit.gov.cn/">京ICP备2024091646号-1</a>
            </p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502056035"
                style="display: inline-block; text-decoration: none"
            >
              <img loading="lazy" src="@/assets/beian.png" style="float: left; width: 17px" />
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京公网安备 11010502056035号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="screenModel" ref="screenModel">
        <div class="foot">
<!--          <div class="left1">-->
<!--            <span @click="gptsProfessional1()" style="color: white;font-size: 14px">油气专业GPTs</span>-->
<!--            <span @click="hidden3" v-if="hid2===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>-->
<!--            <span @click="hidden23" v-if="hid2===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>-->
<!--            <br>-->
<!--            <div v-if="hid2===0" @click="gptsDesign1()" class="community">工程方案设计GPTs</div>-->
<!--            <div v-if="hid2===0" @click="gptsTranslate1()" class="community">解释类GPTs</div>-->
<!--            <div v-if="hid2===0" @click="gptsTechnology1()" class="community">生产工艺优化GPTs</div>-->
<!--          </div>-->

          <div class="left1" >
            <span @click="gpts13()" style="color: white;font-size: 14px">油气通GPT社区</span>
            <span @click="hidden" v-if="hid===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden2" v-if="hid===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid===0" @click="gpts11()" class="community">油气通AI技术研讨专区</div>
            <div v-if="hid===0" @click="gpts21()" class="community">油气通大模型知识库</div>
          </div>
          <div class="left2">
            <span style="color: white;font-size: 14px">联系我们</span>
            <span @click="hidden1" v-if="hid1===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden12" v-if="hid1===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid1===0" class="community">联系我们:<span @click="dialogVisible = true">产品咨询</span></div>
            <div v-if="hid1===0" class="community">联系邮箱： <a href="mailto:anvision@antonoil.com">anvision@antonoil.com</a></div>
            <div v-if="hid1===0" class="community">联系电话：<span style="margin-left: -7px;color:gray;cursor: pointer;font-size: 12px">+86&nbsp;&nbsp;17521198294</span></div>
            <div v-if="hid1===0" class="community">联系地址：<span style="margin-left: -7px;color:gray;cursor: pointer;font-size: 12px">北京市朝阳区东湖渠屏翠西路8号</span></div>



          </div>
          <div class="left3">
          </div>
          <div class="flex">
            <p>北京市怀柔区迎宾南路11号</p>
          </div>
          <div class="flex">
            <p>版权所有 © 北京安迅由气通智能技术有限公司</p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                style="display: inline-block; text-decoration: none"
            >
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京ICP备2024091646号-1
                京公网安备 11010502056035号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <consultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>


</template>


<script>

import consultTip from "@/components/consultTip.vue";

export default {

  name: "",

  props: [],

  components: {consultTip},

  data() {
    return {
      changeVisable:1,
      hid:1,
      hid1:1,
      hid2:1,
      dialogVisible: false,
      model:false,
      showModal: false,
      largeSrc:'',
    }

  },
  // beforeRouteLeave(to, from, next) {
  //   // 保存滚动位置
  //   this.scrollPosition = this.$refs.screenModel.scrollTop;
  //   next();
  // },
  // //进入该页面时，用之前保存的滚动位置赋值
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     // 恢复滚动位置
  //     vm.$nextTick(() => {
  //
  //       vm.$refs.screenModel.scrollTop = vm.scrollPosition;
  //
  //     });
  //   });
  // },
  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    hidden(){
      this.hid=1
    },
    hidden2(){
      this.hid=0
    },
    hidden1(){
      this.hid1=1
    },
    hidden12(){
      this.hid1=0
    },
    hidden3(){
      this.hid2=1
    },
    hidden23(){
      this.hid2=0
    },
    gpts1(){
      window.open("https://www.oilgascommunity.com/sortList?id=40", "_blank");
    },
    gpts2(){
      window.open("https://www.oilgascommunity.com/sortList?id=41", "_blank");
    },
    gpt3(){
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    gptsProfessional(){
      sessionStorage.setItem("skip", 4)
      console.log(localStorage.getItem("home"))
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        window.open("/", "_blank");
      }
    },
    goToEmaulAddress(){
      console.log("5555")
      let address =this.goToEmail("2687567554@aa.com")
      if(address){
        window.location.href=address
      }else{
        return
      }
    },
//定义一个方法获取注册邮箱是属于哪个邮箱的
    goToEmail(email){
      var serverMap = {
        'qq.com': 'http://mail.qq.com',
        'vip.sina.com': 'http://mail.sina.com.cn',
        'sina.com': 'http://mail.sina.com.cn',
        '163.com': 'http://mail.163.com',
        '126.com': 'http://mail.126.com',
        'vip.163.com': 'http://vipmail.163.com/',
        'vip.126.com': 'http://vipmail.163.com/',
        'yeah.net': 'http://www.yeah.net/',
        'sohu.com': 'http://mail.sohu.com/',
        'tom.com': 'http://mail.tom.com/',
        '139.com': 'http://mail.10086.cn/',
        'live.com': 'http://login.live.com/',
        '189.com': 'http://webmail16.189.cn/',
        'eyou.com': 'http://www.eyou.com/',
        '21cn.com': 'http://mail.21cn.com/',
        '188.com': 'http://www.188.com/',
        'yahoo.com': 'http://login.yahoo.com'
      }
      var server = email.split('@')[1]
      return serverMap[server] || ''
    },

    gptsDesign(){
      window.open("/gptDesign", "_blank");
    },
    gptsTranslate(){
      window.open("/gptTranslate", "_blank");
    },
    gptsTechnology(){
      window.open("/gptTechnology", "_blank");
    },

    gptsProfessional1(){
      sessionStorage.setItem("skip", 5)
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        this.$router.push("/");
      }



    },
    gptsDesign1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptOil");
    },
    gptsTranslate1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptTranslate");
    },
    gptsTechnology1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptHole",);
    },
    gpts11(){
      sessionStorage.setItem("skip", 0)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=40';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts21(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=41';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts13(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=39';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    hideDialog() {
      this.dialogVisible = false;
    },
    closeModal() {
      this.showModal = false;
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
  },

  mounted() {

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.screen{
  z-index: 999;
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background-color: #fff;
    padding: 10%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
  .foot {
    padding-top: 15px;
    text-align: left;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
  .yuming{
    color:white;

  }

  .left{
    padding-top: 3vh;
    display: flex;
    color:#FFFFFF;
    .left1{
      margin-left: 2.15944540727903vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left3{
      margin-left: 0.6932409012vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left2{
      margin-left: 2.15944540727903vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community1{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw;
      }
      .up{
        margin:2vh 0 0 0;
        width: 4vw;
        height: auto;
        //background-image:url("../assets/attention.png");
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat
      }
      .down{
        font-size: 0.7709416522vw;
        width:  4vw;
        text-align: center;
        line-height: 3vh;
      }
    }

  }
  .new{
    padding: 3vh 5vh 0 0 ;
    color:#FFFFFF;
    width: fit-content;
    font-size: 0.8709416522vw;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    line-height: 4.33745364647713vh;
    .title{
      text-align: right;
    }
  }
  .right{
    margin-top: 2vh;
    display: flex;
    color:#FFFFFF;
    .left2{
      margin-left: 1vw;
      width: 4vw;
      font-size: 0.7709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 3.33745364647713vh;
      .up{
        width: 4vw;
        height: auto;
        //background-image:url("../assets/attention.png");
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat
      }
      .down{
        width:  4vw;
      }
    }

  }
}
.screenModel{
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .left1{
    width: 90%;
    margin:0 15px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size:12px
    }

  }
  .left2{
    width: 90%;
    margin:0 15px;
    color: white;
    text-align: left;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    line-height: 32px;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size: 12px
    }
  }
  .left3{
    width: 100%;
    margin:15px 0;
    height: 1px;
    background-color: #484848;
  }

  .foot {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }

}
</style>
