<script>
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  methods:{
    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/pipeline")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/digital")
    },
    storage1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/storage")
    },
    training1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/training")
    },
    build1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/build")
    },
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
  }
}
</script>

<template>
  <div ref="station" id="station" class="screenConsult" style="background-color: #3F9DB4;z-index: 2">
    <!--            <div style="display: flex;margin:0 auto;">-->
    <!--              <div style="display: flex;margin:0 auto;text-align: center">-->
    <!--                &lt;!&ndash;              <div style="height:22px;width:22px;text-align: right;margin-top:19px;"><img loading="lazy"&ndash;&gt;-->
    <!--                &lt;!&ndash;                  src="@/assets/icon_second.png"></div>&ndash;&gt;-->
    <!--                <div v-sliden-in="{duration:1200}" class="title" style="margin-top: 15px;color:#371e76">数智油田全场景GPT应用</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <div style="position: sticky;top:-1px;background-color:#3F9DB4;z-index: 3;padding:9px 0">
      <div  style="margin: 0 auto;
    text-align: center;
    height: fit-content;
    opacity: 1;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: 26px;
    color: #371e75;
" v-sliden-in="{duration:800}">2.伴随式数智油田解决方案</div>
    </div>
      <div class="light" v-sliden-in="{duration:800}" style="color: white">利用人工智能技术，建设与地质、工程相结合的数智油田全场景解决方案，赋能数智油田全面升级。
        <span class="light2" style="color: blue">
              <br>如想开展进一步的产品展示和交流，请<span style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                                                         @click="clickConsult('伴随式数智油田解决方案')">点击咨询</span>。
          </span>
      </div>




    <div class="pro2">

      <div v-sliden-in="{}"  @click="station1" class="grand1" style="position: relative;height: fit-content;padding: 0" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;" src="@/assets/home1.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1" >
            智慧场站</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="security1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img  style="border-radius: 9px;"  src="@/assets/home2.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 智慧安防 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="productANxun1" style="position: relative;height: fit-content"  >
        <div class="back">
          <div class="photo">
            <img  style="border-radius: 9px;"  src="@/assets/home3.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1">油气生产智能管控 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="equipmentAnxun1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home4.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1" >设备设施智能监测 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div v-sliden-in="{}" class="grand1" @click="technologyAnxun1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home5.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1">工艺大数据分析</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div v-sliden-in="{}" class="grand1" @click="monitorAnxun1" style="position: relative;height: fit-content"  >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home6.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 石油工程远程<br>监控指挥平台 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
      <div v-sliden-in="{}" class="grand1" @click="networkAnxun1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home7.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 智慧管网 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="basic1" style="position: relative;height: fit-content"  >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home8.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 智慧油气<br>基础设施</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="setting1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/settingsucesse11.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 油气数智化全场景<br>伴随式服务</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="storage1" style="position: relative;height: fit-content"  >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/storage1.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1">智慧储气库 </div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="training1" style="position: relative;height: fit-content" >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/training11.jpg">
          </div>
        </div>
        <div class="back1">
          <div class="title1">智慧培训</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

          </div>
        </div>
      </div>

      <div v-sliden-in="{}" class="grand1" @click="build1" style="position: relative;height: fit-content"  >
        <div class="back">
          <div class="photo">
            <img style="border-radius: 9px;"  src="@/assets/home8.png">
          </div>
        </div>
        <div class="back1">
          <div class="title1"> 智能油气生成优化</div>
          <div class="title2" >
            更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>



    </div>


  </div>
</template>

<style scoped lang="scss">
.screenConsult {
  //z-index: 10;
  height: fit-content;
  padding: 20px 15px;
  background: rgba(255, 255, 255, 1);

  .title {
    margin: 0 auto;
    text-align: center;
    height: fit-content;
    opacity: 1;
    font-size: 20px;
    //color: #53A3F7;
    font-family: Roboto,sans-serif;
    font-weight: bold;
    line-height: 26px;
  }

  .light {
    margin: 15px auto;
    text-align: justify;
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color: #2C3E50;
    line-height: 25px;
  }

  .light2 {
    margin: 20px auto 0 auto;
    text-align: justify;
    width: fit-content;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color: #FF6400;
    line-height: 25px;
  }

  .pro1 {
    height: fit-content;
    width: 100%;
    overflow-x: auto;
    //z-index: 89156;

    .swiper-container {
      width: 100%;
      height: fit-content;
    }
    .swiper-slide {
      text-align: center;
      //font-size: 18px;
      //background-color: #d4e4ff;
      /* Center slide text vertically */
      display: flex;
      //margin-top: 50px;
      justify-content: center;
      align-items: center;
    }
    .swiper-pagination {
      position: relative;
      margin-top: 10px;
      background-color: black;
      //z-index: 10;
    }

    .swiper-pagination-bullet {
      background: black; /* 设置分页器圆点的背景颜色 */
      opacity: 0.5; /* 设置透明度 */
      width: 10px; /* 圆点宽度 */
      height: 10px; /* 圆点高度 */
      border: 1px solid #ccc; /* 圆点边框 */
      border-radius: 50%; /* 圆点边框圆角 */
      margin: 0 5px; /* 圆点间距 */

    }

    .swiper-pagination-bullet-active {
      opacity: 1; /* 设置激活状态的透明度 */
    }
    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0  0 0;
      opacity: 1;
      height: 250px;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        //background-color: white;
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        //border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }
      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        ////box-shadow: 5px 0 14px 0 #d4e4ff;

        // border-radius:6px;
        display: flex;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 10px 15px;
        justify-content: space-between;
      }
      .photo { overflow: hidden;

        border-radius: 9px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        height: 100%;
        ::v-deep .img{
          border-radius: 9px;
        }
      }

      .title1 {
        opacity: 4;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: white;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        margin:auto 0 0 auto;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }

  .pro2 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;

    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 15px 0  0 0;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }
      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
      }
      .photo { overflow: hidden;
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        border-radius: 9px;
        height: 260px;
      }

      .title1 {
        text-align: left;
        font-size: 18px;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        color: white;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        //text-overflow: ellipsis;
        text-overflow: ellipsis;

        width: 40vw;
        overflow: hidden;
        height: fit-content;
        //white-space: nowrap;/
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        margin:auto 0 0 auto;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }

  .pro3 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;
    display: grid;
    grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 0;
    row-gap: 1.8vh;

    .grand1 {
      cursor: pointer;
      width: fit-content;
      min-height: 180px;
      opacity: 1;
      position: relative;
      padding: 9px 6px;

      .back {
        width: 100%;
        height: 100%;
        background: white;
        box-shadow: 5px 0 2px 0px #D4E4FF;
        border: 2px solid #FFFFFF;
        border-radius: 9px;
      }

      .photo { overflow: hidden;
        border-radius: 9px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        margin: 15px auto auto auto;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100px;
        height: 30px;
      }

      .title1 {
        opacity: 4;
        margin-top: 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        font-size: 18px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        //color: #2168DB;
        line-height: 18px;
      }

      .title2 {
        opacity: 4;
        margin: 15px 8px 20px 8px;
        //text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        line-height: 16px;
      }

      .consult {
        position: absolute;
        display: none;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        font-size: 0.80878105141537vw;
        bottom: 3.94437577255871vh;
        left: 1.85540150202195vw;
      }
    }

    .grand2 {
      cursor: pointer;
      width: fit-content;
      min-height: 180px;
      opacity: 1;
      position: relative;
      padding: 9px 6px;

      .back {
        width: 100%;
        height: 100%;
        background: white;
        box-shadow: 5px 0 2px 0px #D4E4FF;
        border: 2px solid #FFFFFF;
        border-radius: 9px;
      }

      .photo { overflow: hidden;
        border-radius: 9px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        margin: 15px auto auto auto;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 110px;
        height: 30px;
      }

      .title1 {
        opacity: 4;
        margin-top: 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        font-size: 18px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        //color: #2168DB;
        line-height: 18px;
      }

      .title2 {
        opacity: 4;
        margin: 15px 8px 20px 8px;
        //text-align: justify;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        line-height: 16px;
      }

      .consult {
        position: absolute;
        display: none;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        font-size: 0.80878105141537vw;
        bottom: 3.94437577255871vh;
        left: 1.85540150202195vw;
      }
    }

  }
}
</style>
