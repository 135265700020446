<script>
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
    expo() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气展馆网站平台。</a><br><span style="color: orange"><a >https://cn.oilgasmall.com/</a></span></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://cn.oilgasmall.com/", "_blank");
      }).catch(() => {

      });
    },
    gptzixun() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气大模型。</a><br><span style="color: orange"><a >https://cn.oilgasinfoai.com/AIAnswer</a></span></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://cn.oilgasinfoai.com/AIAnswer", "_blank");
      }).catch(() => {

      });
    },
    job() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气人才网站平台。</a><br>  <a style="color: orange">https://oilgasjobai.com/</a></div>', '', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://oilgasjobai.com/", "_blank");
      }).catch(() => {

      });
    },
    info() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气知识网站平台。</a><br>  <a style="color: orange">https://cn.oilgasinfoai.com/</a></div>', ' ', {
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        confirmButtonText: '继续访问',
        cancelButtonText: null,

        type: 'warning'
      }).then(() => {
        // 用户确认离开，继续路由跳转
        localStorage.setItem("skip", 0)
        window.open("https://cn.oilgasinfoai.com/", "_blank");
      }).catch(() => {

      });
    },
    wen() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入文心一言网站平台。</a><br>  <a style="color: orange">https://yiyan.baidu.com/</a></div>', ' ', {
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://yiyan.baidu.com/", "_blank");
      }).catch(() => {
      });
    },
    dou() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入豆包网站平台。</a><br>  <a style="color: orange">https://www.doubao.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://www.doubao.com/", "_blank");
      }).catch(() => {

      });    },
    xun() {

      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入讯飞星火网站平台。</a><br>  <a style="color: orange">https://xinghuo.xfyun.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://xinghuo.xfyun.cn/", "_blank");
      }).catch(() => {

      });    },
    tong() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入通义千问网站平台。</a><br>  <a style="color: orange">https://tongyi.aliyun.com/qianwen/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
      }).catch(() => {

      });    },
    zhi() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入智谱清言网站平台。</a><br>  <a style="color: orange">https://chatglm.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://chatglm.cn/", "_blank");
      }).catch(() => {

      });
    },
    teng() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入腾讯混元网站平台。</a><br>  <a style="color: orange">https://hunyuan.tencent.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://hunyuan.tencent.com/", "_blank");
      }).catch(() => {

      });
    },
    tian() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入天工AI网站平台。</a><br>  <a style="color: orange">https://home.tiangong.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://home.tiangong.cn/", "_blank");
      }).catch(() => {

      });
    },
    ge() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入文心一格网站平台。</a><br>  <a style="color: orange">https://yige.baidu.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://yige.baidu.com/", "_blank");
      }).catch(() => {

      });
    },
    wan() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入万彩AI网站平台。</a><br>  <a style="color: orange">https://ai.kezhan365.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://ai.kezhan365.com/", "_blank");
      }).catch(() => {

      });
    },
    yi() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入一帧秒创网站平台。</a><br>  <a style="color: orange">https://aigc.yizhentv.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://aigc.yizhentv.com/", "_blank");
      }).catch(() => {
      });
    },
  }

}
</script>

<template>
<div class="freeGPTsContainer" >
  <div class="part1" >
    <div class="part1_1" v-move-in-duration="{duration:800,direction:'x',offset:'-100'}" style="display:flex;">
      <div style="margin:auto">
        <div class="title11">
          免费油气资讯GTPs
        </div>
        <div class="title22">
          免费为用户提供基于AI大模型技术的油气资讯服务。<br>
          <span style="color: #fb7017">如需定制化的使用体验，请<span class="clickinfo" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw" @click="clickConsult('免费油气资讯GTPs')">点击咨询</span>。</span>
        </div>
      </div>
    </div>
    <div class="part1_2" >
      <div class="part1_2_1" >
        <div class="knowledge" v-move-in-duration="{duration:1200,direction:'x',offset:'100'}" style="height: 50%;width: 100%" @click="info">
          <div v-zoom-in="{duration:600}" class="grand111" style="position: relative;height: fit-content" >
            <div class="back">
              <div class="photo">
                <img class="image" style="border-radius: 9px" src="@/assets/information.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> 油气知识</div>
              <div class="title2">
                更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
              </div>
            </div>
          </div>
        </div>
        <div class="job" v-move-in-duration="{duration:800,direction:'x',offset:'100'}" style="height: 50%;width: 100%" @click="job">
<!--          <a href="/gptKnowledge" target="_blank">-->
            <div v-zoom-in="{duration:600}" class="grand111" style="position: relative;height: fit-content "  >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/job.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> 油气人才</div>
                <div class="title2">
                  更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
<!--          </a>-->
        </div>

      </div>
      <div class="part1_2_2" v-move-in-duration="{duration:1500,direction:'x',offset:'100'}">
        <div  class="grand111" style="position: relative;height: fit-content" @click="gptzixun">
          <div class="back">
            <div class="photo" style="height: 52.5vh;">
              <img class="image" style="border-radius: 9px" src="@/assets/gptzixun.png">
            </div>
          </div>
          <div class="back1">
            <div class="title1">免费油气资讯GPT</div>
            <div class="title2">
              更多&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="part2">
    <div v-move-in-duration="{duration:1200,direction:'x',offset:'-100'}" class="part2_1">
      <!--油田-->
      <div  class="grand2" @click="wen">
        <div class="back">
          <div class="photo">
            <img src="@/assets/wen.png"/>
          </div>
          <div class="title1">
            文心一言<br>
            <img src="@/assets/arrow_right_blue.png">
          </div>

        </div>
      </div>
      <!--        油藏-->
      <div v-sliden-in="{duration:1000}" class="grand2" @click="dou">
        <div class="back">
          <div class="photo"><img src="@/assets/dou.png"/></div>
          <div class="title1">
            <span>豆包AI<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
        </div>
      </div>
      <div v-sliden-in="{duration:1400}" class="grand2" @click="tong">
        <div class="back">
          <div class="photo">
            <img src="@/assets/tong.png"/>
          </div>
          <div class="title1">
            通义千问<br>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Tongyi Qianwen is a large language model independently developed by Aliyun, which can provide users with services and assistance in different fields and tasks through natural language understanding and semantic analysis on the basis of users' natural language input.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:1800}" class="grand2" @click="zhi">
        <div class="back">
          <div class="photo">
            <img src="@/assets/zhi.png"/>
          </div>
          <div class="title1">
            智谱清言<br>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    The first generative AI assistant launched by Beijing Zhi Spectrum Huazhang Technology Co., Ltd, which is based on Zhi Spectrum AI's self-developed Chinese-English bilingual dialogue model ChatGLM2, pre-trained with trillions of characters of text and code, to provide users with intelligent services.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:2200}" class="grand2" @click="xun">
        <div class="back">
          <div class="photo">
            <img src="@/assets/xun.png"/>
          </div>
          <div class="title1">
            讯飞星火<br>
            <img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    The new generation of cognitive intelligence big model launched by KU Xunfei has cross-domain knowledge and language understanding ability, which can understand and perform tasks based on natural dialog way, providing language understanding, knowledge question and answer and other abilities.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:600}" class="grand2" @click="teng">
        <div class="back">
          <div class="photo">
            <img src="@/assets/teng.png"/>
          </div>
          <div class="title1">腾讯混元<br><img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    Tencent Hunyuan is a big language model developed by Tencent, with cross-domain knowledge and natural language understanding capabilities, to achieve based on human-computer natural language dialogue, understand user instructions and perform tasks, to help users realize the human access to information, knowledge and inspiration.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:1000}" class="grand2" @click="tian">
        <div class="back">
          <div class="photo">
            <img src="@/assets/tian.png"/>
          </div>
          <div class="title1">天工AI<br><img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    Tiangong AI is able to realize a variety of application scenarios such as personalized AI search, intelligent Q&A, chat interaction, etc., and has a rich knowledge base covering the fields of science, technology, culture, art, history and so on.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:1400}" class="grand2" @click="ge">
        <div class="back">
          <div class="photo">
            <img src="@/assets/ge.png"/>
          </div>
          <div class="title1">文心一格<br><img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    Wenxin Yige is launched by Baidu relying on its Wenxin big model and flying paddle technology of text to generate pictures of A1 art and creative assistance platform, as the first echelon of the domestic AI picture generation tools and platforms.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:1800}" class="grand2" @click="wan">
        <div class="back">
          <div class="photo">
            <img src="@/assets/wan.png"/>
          </div>
          <div class="title1">万彩AI<br><img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    WanCai AI is a powerful collection of AI content creation tools. In addition to providing AI intelligent writing support, it also integrates powerful AI-generated content features such as AI face change, photo digital person creation and AI short video creation, which further expands the field of AI creation and gives you unlimited possibilities for your creation.-->
          <!--                  </div>-->

        </div>
      </div>
      <div v-sliden-in="{duration:2200}" class="grand2" @click="yi">
        <div class="back">
          <div class="photo">
            <img src="@/assets/yi.png"/>
          </div>
          <div class="title1">一帧秒创<br><img src="@/assets/arrow_right_blue.png"></div>
          <!--                  <div class="title2">-->
          <!--                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.-->
          <!--                  </div>-->

        </div>
      </div>
    </div>
    <div v-move-in-duration="{duration:800,direction:'x',offset:'100'}" class="part2_2" style="display: flex">
      <div style="margin:auto">
        <div class="title11">
          办公提效GPTs
        </div>
        <div class="title22">
          链接主流人工智能模型工具，让您一键触达。<br>
          <span style="color: #fb7017">
          如您想基于主流GPTs开展定制化培训和开发，请<span class="clickinfo" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw" @click="clickConsult('办公提效GPTs')">点击咨询</span>。</span>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.freeGPTsContainer{
  width: 100%;
  height: 110vh;
  //background-color: blue;
  .title11{
    font-size: 1.9vw;
    color: black;
    font-weight: bolder;
    padding: 0vw 2vw;
    width: 100%;
    height: fit-content;
  }
  .title22{
    font-size: 1.2vw;
    line-height: 2vw;
    color: black;
    padding: 2vw 2vw ;
    padding-top: 3vh;
    padding-right: 3vw;
  }
  .part1{
    height: 55vh;
    width: 100%;
    display: flex;
    border: #aeaed7 dashed 1px;
    //box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.3);
    .part1_1{
      height: 100%;
      width: 50%;
      background-color: rgb(253, 253, 253);
      //padding-bottom: 1vw;
      //margin-bottom: 1vw;
    }
    .part1_2{
      height: 100%;
      width: 50%;
      display: flex;
    }
    .part1_2_1{
      width: 50%;height: 100%;background-color: white;padding: 1vh;
    }
    .part1_2_2{
      width: 50%;height: 100%;background-color: white;padding: 1vh 0vh 1vh 1vh;
    }
  }
  .part2{
    border: #aeaed7 dashed 1px;
    //box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 55vh;
    display: flex;
    .part2_1{
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      gap: 1vw;
      height: 100%;
      width: 50%;
      background-color: white;
      //padding: 0 1vw;
    }
    .part2_2{
      height: 100%;
      width: 50%;
      background-color: #fbfbfb;

    }
  }
}


.grand2 {
  cursor: pointer;
  width: 100%;
  margin: auto;
  height: 22vh;
  //padding: 0.98887515451174vh 0 0.98887515451174vh 0;
  opacity: 1;
  position: relative;

  .back {
    width: 100%;
    height: 100%;
    background: white;;;
    //box-shadow: 5px 0 14px 0px #D4E4FF;
    border: 2px solid #D4E4FF;
    border-radius: 9px;
  }

  .photo { overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    opacity: 1.25;
    margin: 2vh auto auto auto;
    display: flex;
    justify-content: center;
    text-align: center;
    width:8.73176761433869vh;
    height: 8.73176761433869vh;
  }

  .title1 {
    opacity: 4;
    margin-top: 2.22496909765142vh;
    text-align: center;
    //display: flex;
    justify-content: center;
    align-items: center;
    height: 2.71940667490729vh;
    font-size: 1.1vw;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    //color: #2168DB;
    line-height: 2.71940667490729vh;
    img{
      padding-top: 1.5vh;
      width: 1vw;
      object-fit: contain;
    }
  }

  .title2 {
    opacity: 4;
    margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
    //text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    height: 14vh;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    font-size: 0.80878105141537vw;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    //color: #2168DB;
    line-height: 2.71940667490729vh;
  }

  .consult {
    position: absolute;
    display: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 0.80878105141537vw;
    bottom: 3.94437577255871vh;
    left: 1.85540150202195vw;
  }
}

.grand2:hover {
  .back {
    background-color: rgb(247, 106, 35);
  }

  .photo { overflow: hidden;

  }

  .title1 {
    color: white;
    margin-top: 3.22496909765142vh;
    //position: absolute;
    //left: 1.85540150202195vw;
    //top: 2.22496909765142vh;
    //height: 2.71940667490729vh;
    //color: white;
    //font-size: 0.97094165222415vw;
  }

  .title2 {
    margin-top: 8.22496909765142vh;
    //text-align: justify;
    color: white;
    font-size: 0.80878105141537vw;
  }

  .consult {
    cursor: pointer;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    color: white;
  }

}

.grand111 {
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  //padding: 0.98887515451174vh 0 0 0;
  opacity: 1;
  height: fit-content;
  position: relative;

  .back {
    width: 100%;
    height: fit-content;
    //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
    background-color: white;
    ////box-shadow: 5px 0 14px 0 #d4e4ff;
    //border-radius: 9px;
    border: white 2px solid;
    // border-radius:6px;
    // display: flex;
  }

  .back1 {
    bottom: 0;
    position: absolute;
    width: 100%;
    color: white;
    height: fit-content;
    background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
    ////box-shadow: 5px 0 14px 0 #d4e4ff;
    border: white 2px solid;
    border-top: none;
    // border-radius:6px;
    display: flex;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 10px 15px;
    justify-content: space-between;
  }

  .photo { overflow: hidden;

    border-radius: 9px;
    border: #dddddd 3px solid;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
    opacity: 1.25;
    width: 100%;
    height: 26vh;
  }

  .title1 {
    opacity: 4;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4vw;;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    color: white;
    //line-height: 35px;
  }

  .title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 4;
    //width: 100%;
    height: 100%;
    // text-align: justify;
    margin: auto 0 0 auto;
    font-size: 1.12vw;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    //color: #2168DB;
    //line-height: 35px;
  }
}
</style>
