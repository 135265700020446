<script >
export default {
  methods:{
    showPartner() {
      this.$emit("showPartner",true)
      // localStorage.setItem("skip", 0)
      // this.dialogconsult = true;
    },
    feedback1() {
      this.$router.push("/feedback")
      // window.open("https://cnmall.antonoil.com/gpts_feedback", "_blank");
    },
    upkefu2() {
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452726083343114&webPageTitle=在线咨询")
    },
  }
}
</script>

<template>
  <div class="screen3" style="">
    <div class="pro3">
      <div>
        <div class="grand1" style="">
          <div  class="cards"
                style=" border-radius: 9px;height: 200px;font-family: 'Times New Roman';text-align: center;justify-content: center;display: flex;align-content: center">
            <div style="margin:auto">
              <div v-left-in100="{duration:800}" style="font-size: 40px;font-weight: bolder">联系</div>
              <div v-sliden-in100="{duration:800}" style="font-size: 65px;font-weight: bolder">我们</div>
            </div>
          </div>
          <div v-right-in100="{duration:800}" class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
               @click="upkefu2()">
            <div style="text-align: center;padding: 25px 0 20px 0">
              <img src="@/assets/kefu.png" style="height: 60px;width: 60px">
            </div>
            <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center;font-weight: bolder">
              在线客服
            </div>
            <div style="margin: 10px auto;text-align: center">
              <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
              <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
            </div>
          </div>
          <div v-left-in100="{duration:800}"  class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
               @click="feedback1()">
            <div style="text-align: center;padding: 25px 0 20px 0">
              <img src="@/assets/communicate.png" style="height: 60px;width: 60px">
            </div>
            <div style="font-family: 'Times New Roman';color: #acdfe0;font-size: 25px;text-align: center;font-weight: bolder">
              服务反馈
            </div>
            <div style="margin: 10px auto;text-align: center">
              <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
              <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
            </div>
          </div>
          <div  v-right-in100="{duration:800}" class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                @click="showPartner()">
            <div style="text-align: center;padding: 25px 0 20px 0">
              <img src="@/assets/hand.png" style="height: 60px;width: 60px">
            </div>
            <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center;font-weight: bolder">
              合作交流
            </div>
            <div style="margin: 10px auto;text-align: center">
              <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
              <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.screen3 {
  //z-index: 10;
  height: fit-content;
  padding: 20px 15px;
  background: rgba(255, 255, 255, 1);

  .title {
    margin: 0 auto;
    text-align: center;
    height: fit-content;
    opacity: 1;
    font-size: 20px;
    //color: #53A3F7;
    font-family: Roboto,sans-serif;
    font-weight: bold;
    line-height: 26px;
  }

  .light {
    margin: 15px auto;
    text-align: justify;
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color: #2C3E50;
    line-height: 25px;
  }

  .light2 {
    margin: 20px auto 0 auto;
    text-align: justify;
    width: fit-content;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color: #FF6400;
    line-height: 25px;
  }
  .pro1 {
    height: fit-content;
    width: 100%;
    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 9px;
      padding:15px 0  0 0;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }
      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
      }
      .photo { overflow: hidden;
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        border-radius: 9px;
        height: 260px;
      }

      .title1 {
        text-align: left;
        font-size: 18px;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        color: white;
        text-overflow: ellipsis;
        width: 50vw;
        overflow: hidden;
        height: fit-content;
        //white-space: nowrap;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }
  .pro11 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;

    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 15px 0 0 0 ;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }
      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
      }
      .photo { overflow: hidden;
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        border-radius: 9px;
        height: 260px;
      }

      .title1 {
        opacity: 4;
        text-align: left;

        font-size: 18px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: white;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        margin:auto 0 0 auto;
        // text-align: justify;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }
  .pro2 {
    height: fit-content;
    width: 100%;
    overflow-x: auto;
    //z-index: 89156;

    .swiper-container {
      width: 100%;
      height: fit-content;
    }
    .swiper-slide {
      text-align: center;
      //font-size: 18px;
      //background-color: #d4e4ff;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-pagination {
      position: relative;
      right: 20px;
      bottom: 20px;
      //z-index: 10;
    }

    .swiper-pagination-bullet {
      background: black; /* 设置分页器圆点的背景颜色 */
      opacity: 0.5; /* 设置透明度 */
    }

    .swiper-pagination-bullet-active {
      opacity: 1; /* 设置激活状态的透明度 */
    }
    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0  0 0;
      opacity: 1;
      height: 250px;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        //border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }
      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        // border-radius:6px;
        display: flex;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 10px 15px;
        justify-content: space-between;
      }
      .photo { overflow: hidden;

        border-radius: 9px;
        border: #dddddd 3px solid;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        height: 260px;
      }

      .title1 {
        opacity: 4;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: Roboto,sans-serif;
        font-weight: bold;
        color: white;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        margin:auto 0 0 auto;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }
  .pro3 {
    .grand1 {
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: 10px;
      height: fit-content;

      .cards {

      }
    }
  }
}
</style>
