<script>
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },
    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company2")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
  }
}
</script>

<template>
  <div class="customizationGPTs" style="height: fit-content;background-color: white">
    <div v-sliden-in="{}" class="customizationTitle" style="color: #371e74">
      3.定制化人工智能服务
    </div>
    <div class="customizationcard" style="display: flex;height: fit-content">
<!--      <div class="cardindex" style="width:6%;">-->
<!--        <div style="display: flex;z-index: 2;background-color: white;;top:44px">-->
<!--        <span>-->
<!--          1-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    油气场景GPT应用开发-->
      <div ref="professional1" style="width:100%;background-color: white" class="screen3">
        <div style=";top:44px;z-index: 2;background-color: white;padding-bottom: 5px">
        <div v-sliden-in="{duration:600}" class="title" style="color:#371e76;color: black;text-align: center">① 油气工程人工智能开发</div>
        </div>
        <div v-sliden-in="{duration:600}" class="light" >
          主要基于安东等工程技术企业的应用，面向业界<br>开放分享。
          <span class="light2" style="color: #193fe0"><br>如需要在更丰富的业务场景下使用，请<span
              style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('油气工程人工智能开发')">点击咨询</span>。</span>

        </div>

        <div class="pro1">
          <div v-sliden-in="{duration:600}" key="yqzy1" @click="oilgpt1" >

            <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/pro1.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" > 工程方案设计类软件</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}"  key="yqzy2" @click="oilgpt21">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/pro7.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 地质解释类软件</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="yqzy3" @click="oilgpt31">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/pro2.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 生产工艺优化类软件</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
    <div class="customizationcard" style="display: flex;height: fit-content">
<!--      <div class="cardindex" style=";width:6%;">-->
<!--        <div style="display: flex;z-index: 2;background-color: white;;top:44px">-->
<!--        <span>-->
<!--          2-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    油气开发作业GPT应用服务-->
      <div class="screenConsult" style="background-color: transparent;width:100%;">
        <div style=";top:44px;z-index: 2;background-color: white;padding-bottom: 5px">
          <div ref="upload" style="display: flex;margin:0 auto;">
            <div style="display: flex;text-align: center;width: 100%">
              <!--              <div style="height:22px;width:21px;text-align: right;margin-top:18px;"><img loading="lazy"-->
              <!--                                                                                          src="@/assets/icon_second.png">-->
              <!--              </div>-->
              <div v-sliden-in="{duration:1200}" class="title" style="padding-top: 15px;color:#371e76;text-align: center;color: black">&nbsp;② 人工智能现场应用服务</div>
            </div>
          </div>
        </div>
        <div v-sliden-in="{duration:1200}" class="light">
          通过GPT辅助油气开发现场作业。
          <span class="light2" style="color: #193fe0"><br>如需提升在现场作业过程中的使用效果，快速落地，请<span
              style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
              @click="clickConsult('人工智能现场应用服务')">点击咨询</span>。</span>
        </div>

        <div class="pro2">
          <div v-sliden-in="{duration:1200}" key="yqkf1"   @click="ground1">
            <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px;" src="@/assets/pro1.png">
                </div>
              </div>
              <div class="back1" style="background-color: transparent">
                <div class="title1"  style="width: fit-content">智能油藏地质  </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:1200}"  key="yqkf2" @click="hole1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img  style="border-radius: 9px;"  src="@/assets/pro2.png">
                </div>
              </div>
              <div class="back1"  style="background-color: transparent">
                <div class="title1" style="width: fit-content"> 智能钻井  </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div  v-sliden-in="{duration:1200}" key="yqkf3" @click="frack1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img  style="border-radius: 9px;"  src="@/assets/pro3.png">
                </div>
              </div>
              <div class="back1"  style="background-color: transparent">
                <div class="title1" style="width: fit-content"> 智能压裂  </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div   v-sliden-in="{duration:1200}" key="yqkf4" @click="getOil1">

            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px;"  src="@/assets/pro4.png">
                </div>
              </div>
              <div class="back1"  style="background-color: transparent">
                <div class="title1"  style="width: fit-content">智能采油气   </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:1200}" key="yqkf5"  @click="monitor1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px;"  src="@/assets/pro5.png">
                </div>
              </div>
              <div class="back1"  style="background-color: transparent">
                <div class="title1" style="width: fit-content"> 地层监测  </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:1200}" key="yqkf6" @click="equipment1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px;"  src="@/assets/pro6.png">
                </div>
              </div>
              <div class="back1"  style="background-color: transparent">
                <div class="title1" style="width: fit-content">设备设施监测  </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>


        </div>


      </div>
    </div >
    <div class="customizationcard" style="display: flex;height: fit-content">
<!--      <div class="cardindex" style="width:6%;">-->
<!--        <div style="display: flex;z-index: 2;background-color: white;;top:44px">-->
<!--        <span>-->
<!--          3-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    企业运营提效GPTs-->
      <div ref="menageServices" class="screenConsult1" style="background-color: transparent;width:100%;">
        <div style=";top:44px;z-index: 2;background-color: white;padding-bottom: 5px">
        <div v-sliden-in="{duration:600}"  class="title" style="color:#371e76;padding-top: 15px;text-align: center;color: black" >③ 企业运营提效GPTs</div>
        </div>
          <div v-sliden-in="{duration:600}"  class="light">
          企业管理GPTs人工智能是基于特定的企业环境<br>训练。
          <span
              class="light2" style="color: #193fe0"><br>需结合企业的自身管理环境，进行定制化训练，仅提供案例和展示服务，如需进一步交流，请<span
              style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('企业运营提效GPTs')">点击咨询</span>。</span>

        </div>

        <div class="pro4">


          <div v-sliden-in="{duration:600}" key="qygl_001"  @click="knowledge1" >
            <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" >企业内部管理类<br>知识GPT开发</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="qygl_002"  @click="data1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew2.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 企业管理数智化<br>整体解决方案</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="qygl_003" @click="wisdom1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 数据驱动决策平台</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="qygl_004" @click="design1">

            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew4.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" >智慧员工社区<br>设计及建设</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="qygl_005"  @click="plat1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew5.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 智慧电商平台设计、<br>建设及运营</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" key="qygl_006" @click="automation1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew6.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 管理流程自动化平台<br>设计及建设</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div >
    <div class="customizationcard" style="display: flex;height: fit-content">
<!--      <div class="cardindex" style=";width:6%;">-->
<!--        <div style="display: flex;z-index: 2;background-color: white;;top:44px">-->
<!--        <span>-->
<!--          4-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    油气企业自建GPT云平台服务-->
      <div ref="companyConduct"  style="background-color: transparent;width:100%;"  class="screen3">
        <div style=";top:44px;z-index: 2;background-color: white;padding-bottom: 5px">
          <div v-sliden-in="{duration:1200}" class="title" style="color:#371e76;padding-top: 15px;text-align: center;color: black">④ 企业自建人工智能平台服务</div>
        </div>
        <div v-sliden-in="{duration:1200}" class="light">
          帮助企业客户建设私有环境下的人工智能云平台，积累各应用场景的模型成果，实现模型的最大化共享和算力资源的充分使用。
          <span class="light2" style="color: #193fe0"><br>如您想在私有环境下自建GPT云平台，请<span
              style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('企业自建GPT云平台')">点击咨询</span>。</span>
        </div>

        <div class="pro1">
          <div v-sliden-in="{duration:1200}" key="zijian1" @click="constructPlat1">

            <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/zijian1.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" >  企业大模型云平台<br>规划及建设</div>
                <div class="title2" style="margin:auto 0 0 auto">
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:1200}" key="zijian2" @click="business1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/zijian2.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 业务场景人工智能<br>开发服务</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div  v-sliden-in="{duration:1200}" key="zijian3" @click="company1">

            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/zijian3.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" > 油气企业数据<br>
                  （知识）治理</div>
                <div class="title2" style="margin:auto 0 0 auto">
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div  v-sliden-in="{duration:1200}" key="zijian4" @click="computingPower1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/zijian4.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 基础算力优化</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div >
    <div class="customizationcard" style="display: flex;height: fit-content">
<!--      <div class="cardindex" style=";width:6%;">-->
<!--        <div style="display: flex;z-index: 2;background-color: white;;top:44px">-->
<!--        <span>-->
<!--          5-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    人工智能全员普及培训人工智能全员普及培训-->
      <div ref="traningConsult" style="background-color: white;width:100%;" class="screen3">
        <div style=";top:44px;z-index: 2;background-color: white;padding-bottom: 5px">
        <div v-sliden-in="{duration:1200}" class="title" style="color:#371e76;padding-top: 15px;text-align: center;color: black">⑤ AI培训及转型咨询</div>
        </div>
        <div v-sliden-in="{duration:1200}" class="light">
          帮助客户企业全员可以使用在线的、开放的大模型人工智能工具，成为人工智能的驾驶员。<br>帮助客户实现人工转型规划制定、项目方案设计、专家咨询服务，提升客户企业竞争力。
          <span class="light2" style="color: #193fe0"><br>如您有培训或咨询需求，请<span style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                                                                @click="clickConsult('AI培训及转型咨询')">点击咨询</span>。</span>
        </div>

        <div class="pro1">

          <div v-sliden-in="{duration:1200}" key="peixun1" @click="traningAi1">

            <div class="grand1" style="position: relative;height: fit-content;padding: 0" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/puji2.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1" > 人工智能全员<br>普及应用培训</div>
                <div class="title2"  style="margin:auto 0 0 auto;">
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:1200}" key="peixun2"  @click="consult1">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/puji1.png">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> 企业人工智能转型咨询</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>


        </div>


      </div>
    </div >

  </div>
</template>

<style scoped lang="scss">
.customizationGPTs{
  background-color: #fdfdfd;
  width: 100%;
  padding: 20px 15px;
  .customizationTitle{
    margin: 0 auto;
    text-align: center;
    //height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: 26px;
    position:sticky;
    top: -1px;
    padding:10px 0;
    background-color: #fdfdfd;
    z-index: 3;

  }
  .cardindex{
    padding: 20px 0;
    span{
      margin: 15px auto ;font-weight: 700;font-size: 20px;font-family: Roboto, sans-serif;
    }
  }
  .screen3 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 0;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding:15px 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: #fbfbfb;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0 0 0 ;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro3 {
      .grand1 {
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 10px;
        height: fit-content;

        .cards {

        }
      }
    }
  }
//  .screen3::before{
//    content: "";
//    position: absolute;
//    left: -3vw;
//    width: 4px;
//    height: 96%;
//z-index
//: 1;
//    background: #d8d8d8;
//  }
  .screenConsult1 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 0px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        //margin-top: 50px;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        margin-top: 10px;
        background-color: black;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
        width: 10px; /* 圆点宽度 */
        height: 10px; /* 圆点高度 */
        border: 1px solid #ccc; /* 圆点边框 */
        border-radius: 50%; /* 圆点边框圆角 */
        margin: 0 5px; /* 圆点间距 */

      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: fit-content;
        position: relative;

        .back {
          width: 100%;
          height:fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 250px;
          ::v-deep .img{
            border-radius: 9px;
          }
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }


    .pro4 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0 0 0 ;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          //text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          margin:auto 0 0 auto;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }
  //.screenConsult1::before{
  //  content: "";
  //  position: absolute;
  //  left: -3vw;
  //  width: 4px;
  //  height: 100%;
  //  z-index
  //  : 1;
  //  background: #d8d8d8;
  //}
  .screenConsult {
    //z-index: 10;
    height: fit-content;
    padding: 20px 0px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        //margin-top: 50px;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        margin-top: 10px;
        background-color: black;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
        width: 10px; /* 圆点宽度 */
        height: 10px; /* 圆点高度 */
        border: 1px solid #ccc; /* 圆点边框 */
        border-radius: 50%; /* 圆点边框圆角 */
        margin: 0 5px; /* 圆点间距 */

      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;
          ::v-deep .img{
            border-radius: 9px;
          }
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          //text-overflow: ellipsis;
          text-overflow: ellipsis;

          width: 40vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;/
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          margin:auto 0 0 auto;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }
  //.screenConsult::before{
  //  content: "";
  //  position: absolute;
  //  left: -3vw;
  //  width: 4px;
  //  height: 100%;
  //  z-index
  //  : 1;
  //  background: #d8d8d8;
  //}
}

</style>
