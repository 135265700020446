<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <div @click="changeheadVisable">
            <kefu @toptop="topTop"></kefu>
            <Anxunheader :title1="'智慧场站解决方案'" :title2="'对油气场站的设备、安防、生产流程等进行全方位数智化管理'" @clickConsult1="clickConsult('油气通')"></Anxunheader>
<!--            <div class="screenmake0">-->
<!--              <div class="heighLight">智慧场站解决方案</div>-->
<!--              <div class="heightLight2" style="font-weight: bold">智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖<span-->
<!--                  style=" color: #FF6400;">对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能</span>。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。-->
<!--              </div>-->
<!--            </div>-->
            <div class="screenmake0" style="padding-bottom: 0px">
<!--              <div class="heighLight">智慧场站解决方案</div>-->
              <video-play :video-path="'https://cn.oilgasgpts.com/static/media/SSP-%E6%99%BA%E6%85%A7%E5%9C%BA%E7%AB%99%E5%B9%B3%E5%8F%B0.mp4'"
                          :title1="'智慧场站方案介绍'"
                          :title="'智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。'">
              </video-play>
            </div>
            <div class="screenmake111" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
              <div class="grid1" style="height: 37vh">
                <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
              </div>
            </div>
            <div class="screenmake2" style="padding-top: 5vh;background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
<!--              <div class="line"></div>-->
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/station01.png" style="border-radius: 9px;background-color: #eaeffb">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">设备接入与管理复杂
                      </div>
                      <div class="title2">
                        设备种类繁多、品牌不一，设备接入和管理过程复杂繁琐。
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/station02.png" style="border-radius: 9px;background-color: #eaeffb">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">作业流程繁琐低效
                      </div>
                      <div class="title2">
                        作业流程往往依赖于人工操作和经验判断，不仅效率低下，而且容易出错。
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/station03.png" style="border-radius: 9px;background-color: #eaeffb">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">运维成本高
                      </div>
                      <div class="title2">
                        传统场站运维需要大量的人工巡检和维护工作，不仅增加了人力成本，还可能因为巡检不及时或维护不到位而导致设备故障或安全事故。
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy" src="@/assets/station04.png" style="border-radius: 9px;background-color: #eaeffb">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据孤岛与信息不透明
                      </div>
                      <div class="title2">
                        不同设备和系统之间往往存在数据孤岛问题，导致数据无法有效共享和利用。
                      </div>
                    </div>
                  </div>


                </div>

              </div>

            </div>
            <div class="screenmake0" style="padding-top:5vh;background-color: white;">
              <div v-sliden-in="{duration:800}" class="heighLight" style="">应用场景</div>
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                        <img loading="lazy" src="@/assets/anxun11.jpg"
                             style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                             @click="showModalTrue(require('@/assets/anxun11.jpg'))">
                      </el-tooltip>
                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        <li><span style="font-weight: bold">智慧场站设计与施工 </span><br>利用BIM技术进行三维可视化设计和施工管理，提高效率和精度，实现可视化管理。
                        </li>
                        <li><span style="font-weight: bold">综合安全监控与环境管理 </span><br>
                          集成视频监控、周界安防、环境监测等系统，实现全方位安全监控和环境管理。
                        </li>
                        <li><span style="font-weight: bold">数字化管理作业票 </span><br>实现作业票线上申请、审批、执行和归档，提高效率，确保作业安全。
                        </li>
                        <li><span style="font-weight: bold">多系统集成与集中控制 </span><br>整合DCS、SIS、FGS等系统，实现一体化控制和集中管理，提高系统协同性。
                        </li>
                        <li><span style="font-weight: bold">自动化分析与计量系统 </span><br>
                          实现水、烃露点、色谱分析和流量计量自动化，确保计量数据准确可靠。
                        </li>
                        <li><span style="font-weight: bold">三维可视化与虚拟巡检 </span><br>建立场站的数字孪生模型，实现三维可视化监控和虚拟巡检，提高管理效率。
                        </li>
                        <li><span style="font-weight: bold">移动端远程管理与监控 </span><br>提供移动应用管理平台，方便现场人员随时随地进行数据查看和管理，实现远程监控和控制。
                        </li>

                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake0" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back" style="background-color: white">

                    <div class="titleDesign">
                      <div class="title2">
                        <li><span style="font-weight: bold">智能生产工艺管理 </span><br>通过远程监控和控制，实现生产、设备、巡检、安防和作业过程的智能化管理。
                        </li>
                        <li><span style="font-weight: bold">综合智慧安防系统 </span><br>利旧性强，适用性广，算法精准，实现周界防护、入侵检测、人脸识别等功能。
                        </li>
                        <li><span style="font-weight: bold"> AR增强现实智能巡检 </span><br>
                          利用AR技术辅助巡检人员，提供设备信息、故障诊断和维修指导。
                        </li>
                        <li><span style="font-weight: bold">智能化实时计量交接 </span><br>实现水、烃露点、色谱分析和流量计量自动化，确保计量数据准确可靠。
                        </li>
                        <li><span style="font-weight: bold">实时设备监测与预警系统 </span><br>实时监测设备运行状态，及时发现故障和异常，并进行预警，保障设备安全运行。
                        </li>
                        <li><span style="font-weight: bold">数字孪生场站系统 </span><br>建立场站的数字孪生模型，实现三维可视化监控和虚拟巡检，提高管理效率和决策水平。
                        </li>
                        <li><span style="font-weight: bold">AR智能作业管理 </span> <br>利用AR技术辅助作业人员，提供作业指导、安全提示和操作规范。
                        </li>

                        <li><span style="font-weight: bold">无人机高效巡检系统 </span> <br>利用无人机进行自动、高频次巡检，结合AI图像分析，精准识别施工、塌方、天然气泄漏等风险，提升巡检效率和安全性。
                        </li>


                      </div>
                    </div>
                    <div class="imgDesign">
                      <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                        <img loading="lazy" src="@/assets/anxun112.jpg"
                             style="border-top-right-radius: 9px;border-bottom-right-radius: 9px"
                             @click="showModalTrue(require('@/assets/anxun112.jpg'))">
                      </el-tooltip>
                    </div>
                  </div>


                </div>
              </div>

            </div>

            <div class="screenmake4">
              <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
              <div class="grid">
                <el-carousel arrow="always" indicator-position="outside" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div v-left-in="{duration:800}" class="imgDesign">
                          <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                            <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>

                          </el-tooltip>
                        </div>
                        <div v-sliden-in="{duration:800}" class="titleDesign">
                          <div class="title1">{{ item.title1 }}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>


                    </div>


                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
            <bottomTip></bottomTip>
          </div>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div ref="totalModel" class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}"  class="title1" style="margin: 10px 0px;"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
               智慧场站
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                对油气场站的设备、安防、生产流程等进行全方位的数智化管理。
              </div>
<!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->
            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div style="font-weight: bolder" class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>

            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div class="heighLight">智慧场站解决方案</div>-->
<!--            <div class="heightLight2" style="font-weight: bold">智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖<span-->
<!--                style=" color: #FF6400;">对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能</span>。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。-->
<!--            </div>-->

<!--          </div>-->
          <div class="screenmake0">
            <div class="heighLight">智慧场站方案介绍</div>
            <video-play-mobile :video-path="'https://cn.oilgasgpts.com/static/media/SSP-%E6%99%BA%E6%85%A7%E5%9C%BA%E7%AB%99%E5%B9%B3%E5%8F%B0.mp4'"
                               :title="'智慧场站解决方案将物联网（IoT）、大数据分析、人工智能（AI）、自动化设备等多种先进技术集成，全面涵盖对场站的设备、巡检、安防和生产等方面，实现在线管理与实时监测预警功能。通过AR等可视化手段高效联动，实现作业标准化、生产可视化、运营一体化、研究协同化、管理精益化的智慧油气田发展愿景。'">

            </video-play-mobile>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="height: 450px">
              <image-up-text-down-mobile v-for="elemnt in yourBenfits" :key="elemnt.title1"  :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down-mobile>
            </div>

          </div>
          <div class="screenmake2" style="padding-top: 15px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/station01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">设备接入与管理复杂
                    </div>
                    <div class="title2">
                      设备种类繁多、品牌不一，设备接入和管理过程复杂繁琐。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/station02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">作业流程繁琐低效
                    </div>
                    <div class="title2">
                      作业流程往往依赖于人工操作和经验判断，不仅效率低下，而且容易出错。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/station03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">运维成本高
                    </div>
                    <div class="title2">
                      传统场站运维需要大量的人工巡检和维护工作，不仅增加了人力成本，还可能因为巡检不及时或维护不到位而导致设备故障或安全事故。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/station04.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据孤岛与信息不透明
                    </div>
                    <div class="title2">
                      不同设备和系统之间往往存在数据孤岛问题，导致数据无法有效共享和利用。
                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">应用场景</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/anxun11.jpg"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           @click="showModalTrue(require('@/assets/anxun11.jpg'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">智慧场站设计与施工 </span><br>利用BIM技术进行三维可视化设计和施工管理，提高效率和精度，实现可视化管理。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">综合安全监控与环境管理 </span><br>
                        集成视频监控、周界安防、环境监测等系统，实现全方位安全监控和环境管理。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">数字化管理作业票 </span><br>实现作业票线上申请、审批、执行和归档，提高效率，确保作业安全。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">多系统集成与集中控制 </span><br>整合DCS、SIS、FGS等系统，实现一体化控制和集中管理，提高系统协同性。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">自动化分析与计量系统 </span><br>
                        实现水、烃露点、色谱分析和流量计量自动化，确保计量数据准确可靠。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">三维可视化与虚拟巡检 </span><br>建立场站的数字孪生模型，实现三维可视化监控和虚拟巡检，提高管理效率。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">移动端远程管理与监控 </span><br>提供移动应用管理平台，方便现场人员随时随地进行数据查看和管理，实现远程监控和控制。
                      </li>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">核心功能</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="background-color: transparent">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/anxun112.jpg"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           @click="showModalTrue(require('@/assets/anxun112.jpg'))">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">智能生产工艺管理 </span><br>通过远程监控和控制，实现生产、设备、巡检、安防和作业过程的智能化管理。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">综合智慧安防系统 </span><br>利旧性强，适用性广，算法精准，实现周界防护、入侵检测、人脸识别等功能。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold"> AR增强现实智能巡检 </span><br>
                        利用AR技术辅助巡检人员，提供设备信息、故障诊断和维修指导。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">智能化实时计量交接 </span><br>实现水、烃露点、色谱分析和流量计量自动化，确保计量数据准确可靠。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">实时设备监测与预警系统 </span><br>实时监测设备运行状态，及时发现故障和异常，并进行预警，保障设备安全运行。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">数字孪生场站系统 </span><br>建立场站的数字孪生模型，实现三维可视化监控和虚拟巡检，提高管理效率和决策水平。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">AR智能作业管理 </span> <br>利用AR技术辅助作业人员，提供作业指导、安全提示和操作规范。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">无人机高效巡检系统 </span> <br>利用无人机进行自动、高频次巡检，结合AI图像分析，精准识别施工、塌方、天然气泄漏等风险，提升巡检效率和安全性。
                      </li>

                    </div>
                  </div>

                </div>


              </div>
            </div>

          </div>

          <div class="screenmake4">
            <div  class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in successMobel" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img :src="item.img" loading="lazy"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vLeftIn} from "@/utils/vLeftIn";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
import imageUpTextDown from "@/views/components/imageUpTextDown.vue";
import ImageUpTextDownMobile from "@/views/components/imageUpTextDownMobile.vue";
import videoPlay from "@/components/videoPlay.vue";
import videoPlayMobile from "@/components/videoPlayMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {
    videoPlayMobile,
    videoPlay,
    ImageUpTextDownMobile,
    PartnerTip, consultTip, headtip, bottomTip, kefu, kefuMobile,Anxunheader,imageUpTextDown},

  data() {
    return {
      yourBenfits:[
        {
          imagepath:'/storagebenefit2.png',
          title1:'智能设计与安全保障',
          title2:'基于智能辅助设计和建设，确保场站高效、安全运行，同时减少施工过程中的风险和资源浪费。'
        },
        {
          imagepath:'/stationbenefit2.png',
          title1:'智能数据交互与可视化',
          title2:'基于智能计量与交接，降低核心运营成本，利用三维可视化技术提高数据的可读性和透明度，为生产优化提供支持。'
        },
        {
          imagepath:'/stationbenefit3.png',
          title1:'业务全流程智能覆盖',
          title2:'实现从作业、巡检、监控到云端部署的全流程智能化管理，减少人工干预，降低劳动强度，同时提升运维效率。'
        },
        {
          imagepath:'/stationbenefit4.png',
          title1:'高效复制与资源节约',
          title2:'提供低成本、快速部署的解决方案，适用于多场站的标准化复制，显著节省时间和资源，实现规模化应用。'
        },
      ],
      changeVisable: 1,
      showModal: false,
      largeSrc: '',
      title: '',
      pageTitle: '智慧场站解决方案_安迅数智-油气通GPT',
      pageDescription: '安迅数智智慧场站解决方案，构建场站数智孪生系统，实现场站智能化管理，包括生产、工艺、安全、巡检、作业、设备等；以及人机智能化交互，保障生产安全、提升管控效率和作业质量。',
      pageKeywords: '智慧场站，生产工艺管理，智慧安防管理，AR 智能作业，AR 智能巡检，设备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',
      model: false,
      scrollback: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/anxun12.png"),
        title1: "智慧场站",
        title2: "<li>国内某增压脱烃站智慧场站项目</li><li>西非某油田智慧场站项目</li><li>伊拉克某油田智慧油田项目</li><li>元坝某净化厂工艺大数据分析平台项目</li>",
      }, {
        img: require("@/assets/anxun13.png"),
        title1: "智慧场站",
        title2: "<li>国内某增压脱烃站智慧场站项目</li><li>西非某油田智慧场站项目</li><li>伊拉克某油田智慧油田项目</li><li>元坝某净化厂工艺大数据分析平台项目</li>",
      },
      ],
      successMobel: [{
        img: require("@/assets/anxun12.png"),
        title1: "智慧场站",
        title2: "<li>国内某增压脱烃站智慧场站项目</li><li>西非某油田智慧场站项目</li><li>伊拉克某油田智慧油田项目</li><li>元坝某净化厂工艺大数据分析平台项目</li>",
      }, {
        img: require("@/assets/anxunMobile13.png"),
        title1: "智慧场站",
        title2: "<li>国内某增压脱烃站智慧场站项目</li><li>西非某油田智慧场站项目</li><li>伊拉克某油田智慧油田项目</li><li>元坝某净化厂工艺大数据分析平台项目</li>",
      },
      ]
    }

  },


  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      // const element = this.$refs.total;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // this.$nextTick(() => {
      //   this.$refs.total.scrollTop = rect
      // });
    },
    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    showModalTrue(item) {
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    console.log("diaoyonguel")
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
        pageTitle: '智慧场站解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智智慧场站解决方案，构建场站数智孪生系统，实现场站智能化管理，包括生产、工艺、安全、巡检、作业、设备等；以及人机智能化交互，保障生产安全、提升管控效率和作业质量。',
        pageKeywords: '智慧场站，生产工艺管理，智慧安防管理，AR 智能作业，AR 智能巡检，设备全生命周期管理，设备在线监测与预警，场站数字孪生，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  //width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 70vh;
    //  padding: 0 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //
    //  .layer2 {
    //    position: absolute;
    //    top: 13.1495673671199vh;
    //    left: 9.17157712305026vw;
    //
    //    .title11{
    //      margin: 3.8207663782vh auto 3.0207663782vh auto;
    //      font-size: 2vw;
    //
    //    }
    //
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      font-size: 3.50606585788562vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      //margin-top: 6.82076637824475vh;
    //      width: 33.45869439630272vw;
    //      height: fit-content;
    //      font-size: 1.0597920277vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: black;
    //      line-height: 4.31520395550062vh;
    //    }
    //
    //    .title4 {
    //      height: 8.77626699629172vh;
    //      font-size: 1.6060658579vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //
    //    .titlle3 {
    //      margin-top: 2.07911001236094vh;
    //      cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //
    //    }
    //
    //    .titlle3:hover {
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}
    .screenmake111 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 75vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);



        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 75vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);

        border-bottom: dashed black 1px;
        position: absolute;
        top:12vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2.5vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              margin:0 auto 2vh auto;
              background-color: white;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;/
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 52vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 85px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        //position: absolute;
        //bottom: 30px;
        //left: 50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            background: transparent;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 15px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: #ebf1fd;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 5px 10px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 50px;
              height: 45px;
              margin: 0 auto;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 164px;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 360px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -95px -10px;
          cursor: pointer;
          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
