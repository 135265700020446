<script>
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },

    meitiProduct(){
      this.$emit("meitiProduct",true)
    }
  }
}
</script>

<template>
  <div class="MediumContainer">
    <div class="titleDesign">
      <div class="title" v-move-in-duration="{duration:1000,direction:'y',offset:'100'}">五、数智多媒体</div>
      <div class="light" v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" style="width: fit-content;margin: auto">
        面向油气行业客户，提供数智化的多媒体服务，助力您的品牌在数智浪潮中高效传播、强势破圈。
        <span class="light2" style="color:#fb6400;">
            <br>如您想进行产品方案展示及交流，请<span class="clickinfo" style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw;cursor: pointer" @click="clickConsult('数智多媒体')">点击咨询</span>。</span>
      </div>
    </div>
    <div class="itemDesign" >
      <div v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" class="part1">
        <div @click="meitiProduct()" class="part1_1">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meiti3.jpg">
            </div>
            <div class="content">
            <span>
              品牌数智设计
            </span>
              <div class="more">
                <span>更多</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
        <div @click="meitiProduct()" class="part1_2">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/metinew1.png">
            </div>
            <div class="content">
            <span>
              智慧社媒运营
            </span>
              <div class="more">
                <span>更多</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" class="part2">
        <div @click="meitiProduct()" class="part2_1">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meiti2.png">
            </div>
            <div class="content">
            <span>
              数字化视频创作
            </span>
              <div class="more">
                <span>更多</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
        <div @click="meitiProduct()" class="part2_2">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meitinew2.png" >
            </div>
            <div class="content">
            <span>
              数智融合体验
            </span>
              <div class="more">
                <span>更多</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.MediumContainer{
  .titleDesign{
    color: black;
    .title{
      margin: 0 auto;
      text-align: center;
      height: 8.2373300371vh;
      opacity: 1;
      font-size: 2.2vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 4.2373300371vh;
    }
    .light{
      font-size: 1.2vw;
      padding: 0 2vw 2vw 2vw;
      line-height: 2vw;
      //font-weight: bolder;
    }
  }
  .itemDesign{
    display: flex;
    height: 82vh;
    .card{
      display: flex;
      width: 100%;
      height: 100%;
      .imgDesign{
        width: 70%;
        height: 100%;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      .content{
        width: 30%;
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        position: relative;
        span{
          margin: auto;
          font-size: 1.4vw;
          font-weight: bolder;
          color: #3a4591;
        }
        .more{
          display: flex;
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 1vw 1vw;
          img{
            margin: auto;
            width: 1.5vw;
            height: 1.5vw;
          }
          span{
            color: #3a4590;
            font-size: 1.2vw;
            font-weight: unset;
          }
        }
      }
    }
    .part1{
      padding: 1vh 1vw;
      width: 50%;
      .part1_1{
        width: 100%;
        height: 50%;
        margin-bottom: 1vh;
      }
      .part1_2{
        width: 100%;
        height: 50%;
        margin-top: 1vh;
      }

    }
    .part2{
      padding: 1vh 0vw;
      width: 50%;
      //flex-direction: column;
      //display: block;
      //gap: 2vh;

      .part2_1{
        padding-bottom: 1vh;
        width: 100%;
        height: 50%;

      }
      .part2_2{
        padding-top: 1vh;
        width: 100%;
        height: 50%;
      }
    }
  }
}

</style>
