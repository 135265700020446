import Vue from 'vue'; // 确保引入 Vue

// const DISTANCE = 20;
// const DURATION = 1000;
const animationMap = new WeakMap();
const ob = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            const animation = animationMap.get(entry.target);
            if (animation ) {
                animation.play();
                ob.unobserve(entry.target);
            }
        }
    }
});

// function isBelowViewport(el) {
//     const rect = el.getBoundingClientRect();
//     console.log("高度：",rect.top)
//     console.log("pingmu高度：",window.innerHeight)
//     return rect.top > window.innerHeight; // 判断元素是否在视口下方
// }

const vMoveInDuration = {

    bind(el,binding) {
        Vue.nextTick(()=>{
            // if (!isBelowViewport(el)) {
            //     console.log("返回")
            //     return; // 如果元素不在视口下方，则不进行动画
            // }
            // console.log("binding.value.direction--------------",'translate'+binding.value.direction+'('+binding.value.offset+'px)')
            // console.log("binding.value.offset--------------",binding.value.offset)
            const animation = el.animate(
                [
                    {
                        transform: 'translate'+binding.value.direction+'('+binding.value.offset+'px)',
                        opacity: 0,

                    },
                    {
                        transform: 'translate'+binding.value.direction+'('+binding.value.offset+'px)',
                        opacity: 0,

                    },
                    {
                        transform: 'translate'+ binding.value.direction+'(0)',
                        opacity: 1,
                    }
                ], {
                    duration: binding.value.duration || 500,
                    easing: 'ease-in-out',
                    delay: binding.value.delays || 0,
                    fill: 'forwards',
                }
            );
            animation.onfinish = () => {
                // 使用 requestAnimationFrame 立即设置最终样式
                requestAnimationFrame(() => {
                    try {
                        el.style.transform = 'translateY(2px)';
                        el.style.opacity = '1';
                        console.log("left1788554239");
                    } catch (err) {
                        console.error("设置最终样式时出错：", err);
                    }
                });
            };

            animation.pause();
            animationMap.set(el, animation);
            ob.observe(el);
            // animation.finished.then(() => {
            //     console.log("动画完成");
            // });

            // 返回动画完成的 Promise
            return animation.finished;
        })


    },
    unbind(el) {
        ob.unobserve(el); // 在指令解除绑定时取消观察
    }
}



export {
    vMoveInDuration
}
