<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
          <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <div @click="changeheadVisable">
            <kefu @toptop="topTop"></kefu>
<!--            <div class="screen1">-->
<!--              -->
<!--              &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->

<!--              &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--              <div class="layer2">-->
<!--                <div v-sliden-instep="{duration:800}"  class="title1"><img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>-->
<!--                <div v-sliden-instep="{duration:1600}" class="title11" style="font-weight: bolder;" >工艺大数据分析解决方案</div>-->
<!--                <div v-sliden-instep="{duration:2400}" class="title2" style="font-weight: bolder">专为复杂工艺流程的净化炼化厂设计，实现工艺参数、流程的精准调优，实现高效稳定的生产运营</div>-->
<!--                <div v-sliden-instep="{duration:3000}" class="title4" style="font-weight: bolder">油气数智，简单易行</div>-->
<!--                <div class="titlle3" @click="clickConsult('油气通')" style="font-weight: bolder">-->
<!--                  产品咨询-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--              <div class="layer3"></div>-->
<!--            </div>-->
            <Anxunheader :title1="'工艺大数据分析解决方案'" :title2="'专为复杂工艺流程的净化炼化厂设计，实现工艺参数、流程的精准调优，实现高效稳定的生产运营'" @clickConsult1="clickConsult('油气通')"></Anxunheader>
<!--            <div class="screenmake0">-->
<!--              <div class="heighLight">-->
<!--                工艺大数据分析系统解决方案</div>-->
<!--              <div class="heightLight2" style="font-weight: bold">工艺大数据分析系统解决方案专为复杂工艺流程的净化炼化厂设计，深度融合了大数据、神经网络算法以及人工智能技术，实现了<span style=" color: #FF6400;">工艺参数的精准调优、设备运行的实时预警、节能降耗的有效管理、多维度的对比分析以及科学的决策支持</span>，全面助力企业实现高效稳定的生产运营。</div>-->
<!--            </div>-->
            <div class="screenmake0" style="padding-bottom: 0px">
<!--                            <div class="heighLight">-->
<!--                              工艺大数据分析系统解决方案</div>-->
              <video-play :video-path="'https://cn.oilgasgpts.com/static/media/APDS-%E5%B7%A5%E8%89%BA%E5%A4%A7%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E7%B3%BB%E7%BB%9F-20230515.mp4'"
                          :title1="'工艺大数据分析系统方案介绍'"
                          :title="'工艺大数据分析系统解决方案专为复杂工艺流程的净化炼化厂设计，深度融合了大数据、神经网络算法以及人工智能技术，实现了工艺参数的精准调优、设备运行的实时预警、节能降耗的有效管理、多维度的对比分析以及科学的决策支持，全面助力企业实现高效稳定的生产运营。'">

              </video-play>
            </div>
            <div class="screenmake0" style="background-color: white;padding-bottom: 0px">
              <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
              <div v-sliden-in="{duration:800}" class="grid1">
                <div class="card">
                  <div class="back">
                    <div style="display: flex;justify-content: center">
                      <div class="imgDesign">
                        <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu01.png">
                      </div>
                      <div class="title1">效率提升</div>

                    </div>

                    <div class="titleDesign">
                      <div class="title2">采用先进技术优化流程，显著提升客户效率，降低成本，加速业务目标实现。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div style="display: flex;justify-content: center">
                      <div class="imgDesign">
                        <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu02.png">
                      </div>
                      <div class="title1">效益增长</div>

                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        优化资源，提高生产效率，拓展市场，助力客户增收创利。
                      </div>
                    </div>
                  </div>


                </div>
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div style="display: flex">-->
<!--                      <div class="imgDesign">-->
<!--                        <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu03.png">-->
<!--                      </div>-->
<!--                      <div class="title1">客户体验优化</div>-->

<!--                    </div>-->
<!--                    <div class="titleDesign">-->
<!--                      <div class="title2">-->
<!--                        注重个性化服务，满足客户需求，提升满意度和忠诚度。-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->


<!--                </div>-->
                <div class="card">
                  <div class="back">
                    <div style="display: flex;justify-content: center">
                      <div class="imgDesign">
                        <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu04.png">
                      </div>
                      <div class="title1">降低风险</div>

                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        引入先进风险管理，完善保障机制，有效降低客户业务风险，保障稳健发展。
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake3" style="padding-top: 5vh">
              <div v-sliden-in="{duration:800}" class="heighLight" >   业务痛点与挑战</div>
<!--              <div class="line"></div>-->
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto; ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/technology01.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">
                        数据孤岛与信息共享难题
                      </div>
                      <div class="title2">
                        由于不同系统和设备间的数据无法有效整合和共享，形成了数据孤岛现象。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto; ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/technology02.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">数据质量参差不齐
                      </div>
                      <div class="title2">
                        生产中产生的数据可能存在缺失、错误或不一致的情况，直接影响到数据分析的准确性和可靠性。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto; ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/technology03.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">工艺过程复杂性与不确定性
                      </div>
                      <div class="title2">
                        工业生产过程往往涉及多个环节和多个因素，工艺流程复杂多变且存在不确定性。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto; ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/technology04.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">决策支持不足
                      </div>
                      <div class="title2">
                        由于数据整合不足、分析工具缺乏或分析深度不够等原因，导致无法为管理层提供足够的决策支持。
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="screenmake0" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight" >核心功能</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign">
                      <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                        <img loading="lazy" @click="showModalTrue(require('@/assets/img_4.png'))" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="@/assets/img_4.png">
                      </el-tooltip>
                    </div>
                    <div class="titleDesign">
                      <div class="title2">
                        <li><span style="font-weight: bold">数据自动采集 </span><br>对实时数据库、LIMS数据库及电力数据进行自动化采集、分析、计算、统计。</li>
                        <li style="margin-top: 2vh"><span style="font-weight: bold;">报表配置与展示 </span><br>实现生产日报、技术月报、能耗报表、财务报表的动态配置导出。</li>
                        <li style="margin-top: 2vh"><span style="font-weight: bold">效果评估 </span><br>数据自动同步、实时分析，监控生产现场设备与物料。</li>

                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>


            <div class="screenmake4">
              <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
              <div class="grid">
                <el-carousel indicator-position="outside" arrow="always" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div v-left-in="{duration:800}" class="imgDesign">
                          <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                            <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>

                          </el-tooltip>      </div>
                        <div v-sliden-in="{duration:800}" class="titleDesign">
                          <div class="title1">{{item.title1}}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>


                    </div>


                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
            <bottomTip></bottomTip></div>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content" >
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel"  ref="totalModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1"><img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                工艺大数据分析
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">专为复杂工艺流程的净化炼化厂设计，实现工艺参数、流程的精准调优，实现高效稳定的生产运营。</div>
<!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->

            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">-->
<!--              工艺大数据分析系统解决方案</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2" style="font-weight: bold">工艺大数据分析系统解决方案专为复杂工艺流程的净化炼化厂设计，深度融合了大数据、神经网络算法以及人工智能技术，实现了<span style=" color: #FF6400;">工艺参数的精准调优、设备运行的实时预警、节能降耗的有效管理、多维度的对比分析以及科学的决策支持</span>，全面助力企业实现高效稳定的生产运营。</div>-->
<!--          </div>-->
          <div class="screenmake0">
                        <div v-sliden-in="{duration:800}" class="heighLight">
                          工艺大数据分析系统方案介绍</div>
            <video-play-mobile :video-path="'https://cn.oilgasgpts.com/static/media/APDS-%E5%B7%A5%E8%89%BA%E5%A4%A7%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E7%B3%BB%E7%BB%9F-20230515.mp4'"
                               :title="'工艺大数据分析系统解决方案专为复杂工艺流程的净化炼化厂设计，深度融合了大数据、神经网络算法以及人工智能技术，实现了工艺参数的精准调优、设备运行的实时预警、节能降耗的有效管理、多维度的对比分析以及科学的决策支持，全面助力企业实现高效稳定的生产运营。'">

            </video-play-mobile>
          </div>
          <div class="screenmake0" style="background-color: white">
            <div  v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div class="grid1">
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 35px;margin-top: 3px" src="@/assets/technologyu01.png">
                    </div>
                    <div class="title1">效率提升</div>

                  </div>

                  <div class="titleDesign">
                    <div class="title2">采用先进技术优化流程，显著提升客户效率，降低成本，加速业务目标实现。
                    </div>
                  </div>
                </div>


              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 40px" src="@/assets/technologyu02.png">
                    </div>
                    <div class="title1">效益增长</div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      优化资源，提高生产效率，拓展市场，助力客户增收创利。
                    </div>
                  </div>
                </div>


              </div>
              <div  v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 34px;margin-top: 2px" src="@/assets/technologyu03.png">
                    </div>
                    <div class="title1">客户体验<br>优化</div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      注重个性化服务，满足客户需求，提升满意度和忠诚度。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 32px;margin-top: 3px" src="@/assets/technologyu04.png">
                    </div>
                    <div class="title1">风险降低</div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      引入先进风险管理，完善保障机制，有效降低客户业务风险，保障稳健发展。
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake5" style="padding-top: 15px">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据孤岛与信息共享难题
                    </div>
                    <div class="title2">
                      由于不同系统和设备间的数据无法有效整合和共享，形成了数据孤岛现象。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">数据质量参差不齐
                    </div>
                    <div class="title2">
                      生产中产生的数据可能存在缺失、错误或不一致的情况，直接影响到数据分析的准确性和可靠性。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">工艺过程复杂性与不确定性
                    </div>
                    <div class="title2">
                      工业生产过程往往涉及多个环节和多个因素，工艺流程复杂多变且存在不确定性。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology04.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">决策支持不足
                    </div>
                    <div class="title2">
                      由于数据整合不足、分析工具缺乏或分析深度不够等原因，导致无法为管理层提供足够的决策支持。

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight" style="margin-top:15px">核心功能</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="background-color: transparent;">
                  <div class="imgDesign">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="@/assets/img_4.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">数据自动采集 </span><br>对实时数据库、LIMS数据库及电力数据进行自动化采集、分析、计算、统计。</li>
                      <li><span style="font-weight: bold">报表配置与展示 </span><br>实现生产日报、技术月报、能耗报表、财务报表的动态配置导出。</li>
                      <li><span style="font-weight: bold">效果评估 </span><br>数据自动同步、实时分析，监控生产现场设备与物料。</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vZoomIn} from "@/utils/vZoomIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import {vLeftIn} from "@/utils/vLeftIn";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
import videoPlay from "@/components/videoPlay.vue";
import videoPlayMobile from "@/components/videoPlayMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {videoPlayMobile,videoPlay, Anxunheader, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      showModal: false,
      largeSrc:'',
      title:'',
      pageTitle: '工艺大数据分析系统解决方案_安迅数智-油气通GPT',
      pageDescription: '安迅数智工艺大数据分析系统，实时数据和LIMS数据为核心的工艺大数据分析系统，为油气场站生产建设的提升盈利能力，实现效益最大化，提升企业核心竞争力。',
      pageKeywords: '场站工艺管理，工艺大数据分析系统，工艺大数据分析系统，安迅数智，油气通GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      scrollback:false,
      success:[{
        img:require("@/assets/anxun52.png"),
        title1:"工艺大数据分析系统",
        title2:"<li>广元某净化厂工艺大数据分析系统</li><li>达州某净化厂工艺大数据分析系统</li>",
      },{
        img:require("@/assets/anxun53.png"),
        title1:"工艺大数据分析系统",
        title2:"<li>广元某净化厂工艺大数据分析系统</li><li>达州某净化厂工艺大数据分析系统</li>",
      },
      ]
    }

  },


  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '工艺大数据分析系统解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智工艺大数据分析系统，实时数据和LIMS数据为核心的工艺大数据分析系统，为油气场站生产建设的提升盈利能力，实现效益最大化，提升企业核心竞争力。',
        pageKeywords: '场站工艺管理，工艺大数据分析系统，工艺大数据分析系统，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 74vh;
    //  padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //
    //  .layer2 {
    //    position: absolute;
    //    top: 18.1495673671199vh;
    //    left: 9.17157712305026vw;
    //    .title11{
    //      margin: 3.8207663782vh auto 3.0207663782vh auto ;
    //      font-size: 2vw;
    //
    //    }
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      //font-size: 3.50606585788562vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      //line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      //margin-top: 6.82076637824475vh;
    //      width: 33.45869439630272vw;
    //      height: fit-content;
    //      font-size: 1.0597920277vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: black;
    //      line-height: 4.31520395550062vh;
    //    }
    //    .title4{
    //      height: 8.77626699629172vh;
    //      font-size: 1.6060658579vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //    .titlle3{
    //      margin-top: 2.07911001236094vh;
    //      cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //
    //    }
    //    .titlle3:hover{
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}
    .screenmake3{
      padding: 0  8.09763142692085vw 5.78739184177998vh  8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:12.8vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr)  minmax(0,1fr)  minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
             .imgDesign{
              width:5vh;
              height:5vh;
              background-color: white;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin: 2vh auto 0 auto;
                padding:0 ;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin:3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:52vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 0;
              margin:auto 1vw auto auto;
              color: black;
              width: 40%;
              float: right;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        width: 80%;
        margin: auto;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:25vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:6vh;
              margin:3vh 1vw 0  0vw;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .title1{
              margin-top: 4vh;
              font-size: 1.1709416522vw;
              font-weight: bold;
            }
            .titleDesign{
              padding: 0 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin:0 auto;
              color: black;
              width:100%;
              float: right;

              .title2{
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:55vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              .title1{
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;
                text-align: left;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 58vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 670px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        left: 9.17157712305026vw;

        .title1 {
          width:200px;
        ;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        //position: absolute;
        //bottom:30px;
        //left:50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin:auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake5{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            background: white;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:195px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 25px;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 0;
        row-gap: 15px;

        .card{
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height:185px ;
          background-color: white;
          padding:5px 10px;
          opacity: 1;
          .title1{
            height: 45px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin: 0 5px;
            text-align: justify;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 40px;
              height:38px;
              margin:0 10px 0 5px;
              opacity: 1;
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:193px;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 305px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
