<script>
import { throttle } from 'lodash';
export default {
  data(){
    return{
      activeID:"",
      titleDesignElements: null
    }
  },
  methods:{
    handleScroll2() {
      // 获取所有 titleDesign 元素
      // 遍历所有 titleDesign 元素
      this.titleDesignElements.forEach((element) => {
        const style = window.getComputedStyle(element);
        const rect = element.getBoundingClientRect();
        // 检查元素是否为 sticky 定位且顶部位置为 0（或页面顶部）
        if (style.position === 'sticky' && (rect.top < 100 && rect.top>0)) {
          this.activeID=element.id
          // this.activeID = this.titleDesignElements[index].id
          // let activeDom = document.getElementById(`setclass_${element.id}`)
          // console.log("activeDom", activeDom)
          // console.log("%O", activeDom)
          // const hasClass = activeDom.classList.contains('is-active')
          // this.$nextTick(() => {
          //   if(hasClass){
          //     activeDom.classList.add('is-active')
          //   } else{
          //     activeDom.classList.remove('is-active')
          //   }
          // })

        }
      });
    },
  },
  mounted() {
    this.titleDesignElements = document.querySelectorAll('.cardindex');
    const throttledScroll = throttle(this.handleScroll2, 200);
    document.getElementById("totalModel").addEventListener('scroll', throttledScroll);

  },
  destroyed() {
    // 页面销毁时移除滚动事件监听
    document.getElementById("totalModel").removeEventListener('scroll', this.handleScroll2);
  },
  watch: {
    activeID(newVal) {
      console.log("activeID updated:", newVal);
    }
  }
}
</script>

<template>
  <div class="customizationGPTs" style="height: fit-content">
    <div class="customizationTitle">
      <span>
        免费油气咨询及办公辅助GPTs
      </span>
      <div class="selectors" >
        <div class="selector" id="setclass_APP1" :class="{'is-active': activeID === 'APP1'}">
          <span>油气行业资讯 {{activeID}}</span>
        </div>
        <div class="selector" id="setclass_APP2" :class="{'is-active': activeID === 'APP2'}">
          <span>办公辅助提效 {{activeID}}</span>
        </div>
      </div>
    </div>
    <div class="card" style="display: flex;height: fit-content">
      <div style="width:20%;">
        <div class="cardindex" key="key1" id="APP1" style="display: flex;z-index: 2;background-color: white;position: sticky;top:40px">
        <span   style="margin: 20px auto ;font-weight: 700;font-size: 40px" >
          1.
         </span>
        </div>
      </div>

      <!--    油气资讯GPTss-->
      <div ref="zixun" class="screen13" style="background-color: transparent">

        <div v-sliden-in="{duration:600}"  class="title" style="color:#371e76">&nbsp;油气行业资讯GPTs</div>
        <div v-sliden-in="{duration:600}" class="light" style="margin:15px auto">
          免费为用户提供基于AI大模型技术的油气资讯服务。
          <span
              class="light2"> <br>因AI模型无法做到完全精准，且受算力限制，如想获得更好的使用效果，请<span
              style="color: #2F6EFF;cursor: pointer" @click="clickConsult('油气行业资讯GPTs')">点击咨询</span>。</span>

        </div>
        <div class="pro1">
          <!--采油气-->

          <!--                  <div v-sliden-in="{duration:600}"  @click="oilgpt1" >-->


          <div v-sliden-in="{duration:600}"  @click="info1" key="yqzx_002"  >

            <div class="grand1" style="position: relative;height: fit-content;padding:0" >
              <div class="back">
                <div class="photo">
                  <img src="@/assets/information.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1" >油气知识 </div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" @click="expo1" key="yqzx_001">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content" >
              <div class="back">
                <div class="photo">
                  <img src="@/assets/expo.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> 油气展馆</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
          </div>
          <div v-sliden-in="{duration:600}" @click="job1"  key="yqzx_003">
            <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
            <div class="grand1" style="position: relative;height: fit-content"  >
              <div class="back">
                <div class="photo">
                  <img src="@/assets/job.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> 油气人才</div>
                <div class="title2" >
                  更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div >
    <div class="card" style="display: flex;height: fit-content">
      <div style="width:20%;">
        <div class="cardindex" key="key2" id="APP2" style="display: flex;z-index: 2;background-color: white;position: sticky;top:40px">
        <span  style="margin: 20px auto ;font-weight: 700;font-size: 40px">
          2.
         </span>
        </div>
      </div>
      <!--    办公辅助提效GPTs-->
      <div class="screenGeneral" style="background-color:white ">
        <!--            <div ref="general" style="display: flex;margin:0 auto 0 auto;background-color:white">-->
        <!--              <div style="display: flex;margin:0 auto;text-align: center">-->
        <!--                <div v-sliden-in="{duration:800}" class="title" style="color:#371e76">&nbsp;办公辅助提效GPTs</div>-->
        <!--              </div>-->
        <!--            </div>-->
        <div style="display:flex;">
              <span style="
              color:#371e75;
              margin: 0 auto;
              text-align: center;
              height: fit-content;
              opacity: 1;
              font-size: 20px;
              font-family: Roboto, sans-serif;
              font-weight: bold;
              line-height: 26px;">
                办公辅助提效GPT</span>
        </div>

        <div v-sliden-in="{duration:800}" class="light">
          线上通用性GPTs，基于AI大模型能力，辅助用户改进工作效率。
          <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请<span
              style="color: #2F6EFF;cursor: pointer" @click="clickConsult('办公辅助提效GPTs')">点击咨询</span>。</span>

        </div>

        <div class="pro1">
          <!--油田-->
          <div v-sliden-in="{duration:800}" key="ty1" class="grand2" @click="wen1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/wen.png"/>
              </div>
              <div>
                <div class="title1">文心一言</div>

              </div>

            </div>
          </div>
          <!--        油藏-->
          <div v-sliden-in="{duration:1000}" key="ty2" class="grand2" @click="dou1">
            <div class="back">
              <div class="photo"><img loading="lazy" src="@/assets/dou.png"/></div>
              <div>
                <div class="title1">豆包AI</div>

              </div>
            </div>
          </div>

          <!--        智能-->
          <div v-sliden-in="{duration:1200}" key="ty3" class="grand2" @click="tong1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/tong.png" style="height: 85%;margin-top: 7.5%"/>
              </div>
              <div>
                <div class="title1">通义千问</div>

              </div>
            </div>
          </div>
          <!--采油气-->
          <div v-sliden-in="{duration:800}" key="ty4" class="grand2" @click="zhi1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/zhi.png" style="height: 75%;margin-top: 10%"/>
              </div>
              <div>
                <div class="title1">智谱清言</div>

              </div>
            </div>
          </div>

          <!--        智能-->
          <div v-sliden-in="{duration:1000}" key="ty5" class="grand2" @click="xun1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/xun.png" style="height:85%;margin-top: 5%"/>
              </div>
              <div>
                <div class="title1">讯飞星火</div>

              </div>
            </div>
          </div>

          <!--采油气-->
          <div v-sliden-in="{duration:1200}" key="ty6" class="grand2" @click="teng1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/teng.png" style="height: 90%;margin-top: 5%"/>
              </div>
              <div>
                <div class="title1">腾讯混元</div>

              </div>
            </div>
          </div>

          <!--采油气-->
          <div v-sliden-in="{duration:800}" key="ty7" class="grand2" @click="tian1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/tian.png"/>
              </div>
              <div>
                <div class="title1">天工AI</div>

              </div>
            </div>
          </div>
          <!--采油气-->
          <div v-sliden-in="{duration:1200}" key="ty8" class="grand2" @click="ge1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/wen.png"/>
              </div>
              <div>
                <div class="title1">文心一格</div>

              </div>
            </div>
          </div>

          <!--采油气-->
          <div v-sliden-in="{duration:1200}" key="ty9" class="grand2" @click="wan1">
            <div class="back">
              <div class="photo">
                <img loading="lazy" src="@/assets/wan.png" style="height: 90%;margin-top: 5%"/>
              </div>
              <div>
                <div class="title1">万彩AI</div>

              </div>
            </div>
          </div>

          <!--采油气-->
          <!--      <div class="grand2" @click="yi1">-->
          <!--        <div class="back">-->
          <!--          <div class="photo">-->
          <!--            <img loading="lazy" src="@/assets/yi.png" style="height: 85%;margin-top: 10%"/>-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <div class="title1">一帧秒创</div>-->

          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->

        </div>

      </div>
    </div >
  </div>
</template>

<style scoped lang="scss">
.is-active{
  color: red !important;
}
.customizationGPTs {
  background-color: white;
  width: 100%;
  .customizationTitle{
    margin: 0 auto;

    text-align: center;
    //height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: 26px;
    position: sticky;
    top: 0;
    padding:10px 0;
    background-color: white;
    z-index: 99;
    .selectors{
      margin-top:10px;
      margin-left: 2.5%;
      width: 95%;
      height: 40px;
      //background-color: #eb7c31;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      background-color: #371e75;
      box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
      border-bottom-left-radius: 10px;
      .selector{
        width: 100%;display: flex;border-bottom-left-radius: 10px;
        box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
        color: white;
      }
      span{
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        //height: -moz-fit-content;
        height: fit-content;
        opacity: 1;
        font-size: 20px;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        line-height: 26px;

      }
    }
  }
  .screen13 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;


    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 0;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      //height: fit-content;
      width: 100%;
      //z-index: 89156;
      .swiper-container0{
        position: relative;
      }
      ::v-deep .swiper-pagination {
        position: absolute;
      }
      ::v-deep .swiper-pagination-bullet {
        width: 5px !important;
        height: 25px !important;

        //position: absolute;
      }

      .swiper-container-vertical > .swiper-pagination-bullets{
        left:0px !important;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(47, 110, 255);
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        margin:0 0 0 auto;
        border-radius: 6px;
        padding: 15px  0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }
  .screen13::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
  .screenGeneral {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 8px 4px 0 4px;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background:  white;;
          padding: 0 5px;
          //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
          border: 1px solid #C9C9C9;
          border-radius: 9px;
          //display: flex;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 8px auto 6px auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35px;
          height: 40px;
        }

        .title1 {
          opacity: 4;
          margin: 2px auto 8px auto;
          text-align: center;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          //font-weight: bold;
          color: #0a0a0a ;
          line-height: 25px;
        }

        .title2 {
          width: 110px;
          opacity: 4;
          margin: 5px 3px 5px 0;
          height: 14px;
          word-wrap: break-word; //换行
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }
    }

  }
  .screenGenera::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
}
</style>
