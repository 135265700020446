<script>
export default {
  props:{
    title:{
      type:String,
      default:"工程方案设计软件"
    },
    imgpath:{
      type:String,
      default: require("@/assets/pro1.jpg")
    }
  }
}
</script>

<template>
<div class="boxcontainer">
  <div class="imgDesign">
    <img :src="imgpath">
  </div>
  <div class="mengban">
    <div class="name" v-html="title">
    </div>
    <div class="more">
      更多
      <img style="width: 0.6vw;object-fit: contain;padding: 0 0.5vw" src="@/assets/more_btn_icon.png">
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.boxcontainer{
  //height: 100%;
  font-size: 1.4vw;
  color: white;
  position: relative;
  border-radius: 9px;
  overflow: hidden;
  .imgDesign{
    height: 100%;
  }
  img{
    width: 100%;
    height: 100%;
    border-radius: 9px;
  }
  .mengban{
    padding: 1vw 1vw;
    position: absolute;
    display: flex;
    width: 100%;
    height: fit-content;
    bottom: 0;
    background: linear-gradient(to top, rgb(27, 30, 118) 0%, rgb(27, 30, 118) 50%, transparent 100%);
    .name{
      font-weight: bolder;
      width: 80%;
    }
    .more{
      width: 20%;
      display: flex;
      float: right;
      right: 0;
    }
  }
  //background-image: url("../../assets/pro1.jpg");
}
</style>
