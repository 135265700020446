<script>
// import { throttle } from 'lodash';
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  data(){
    return{
      activeID1:"",
      abc:12,
      titleDesignElements: null
    }
  },
  methods:{
    ToBigmodel(){
      window.open("https://cn.oilgasinfoai.com/AiPop")
    },
    dou1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入豆包网站平台。</a><br>  <a style="color: orange">https://www.doubao.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.doubao.com/';
        this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    xun1() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入讯飞星火网站平台。</a><br>  <a style="color: orange">https://xinghuo.xfyun.cn/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://xinghuo.xfyun.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    tong1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入通义千问网站平台。</a><br>  <a style="color: orange">https://tongyi.aliyun.com/qianwen/</a>', ' ', {
        confirmButtonText: '继续访问',
        dangerouslyUseHTMLString: true,
        cancelButtonText: '取消',
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://tongyi.aliyun.com/qianwen/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    zhi1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入智谱清言网站平台。</a><br>  <a style="color: orange">https://chatglm.cn/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://chatglm.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    teng1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入腾讯混元网站平台。</a><br>  <a style="color: orange">https://hunyuan.tencent.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://hunyuan.tencent.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    tian1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入天工AI网站平台。</a><br>  <a style="color: orange">https://home.tiangong.cn/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://home.tiangong.cn/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    ge1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入文心一格网站平台。</a><br>  <a style="color: orange">https://yige.baidu.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://yige.baidu.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    wan1() {
      this.$confirm('<a style="color: white">您即将离开安迅数智油气通平台，进入万彩AI网站平台。</a><br>  <a style="color: orange">https://ai.kezhan365.com/</a>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        showCancelButton:false,
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://ai.kezhan365.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });
    },
    expo1() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气展馆网站平台。</a><br><a style="color: orange">https://cn.oilgasmall.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        customClass: 'del-model',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,

        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
        const externalLink = 'https://cn.oilgasmall.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });

    },
    job1() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气人才网站平台。</a><br>  <a style="color: orange">https://oilgasjobai.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        customClass:'text',
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://oilgasjobai.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    info1() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入油气知识网站平台。</a><br>  <a style="color: orange">https://cn.oilgasinfoai.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://cn.oilgasinfoai.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    wen1() {
      this.$confirm('<div><a style="color: white">您即将离开安迅数智油气通平台，进入文心一言网站平台。</a><br>  <a style="color: orange">https://yiyan.baidu.com/</a></div>', ' ', {
        confirmButtonText: '继续访问',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        showCancelButton:false,
        type: 'warning'
      }).then(() => {
        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://yiyan.baidu.com/';
        // 导航到外部链接处理路由，并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });
    },
    handleScroll2() {
      // // 获取所有 titleDesign 元素
      // const titleDesignElements = document.querySelectorAll('.cardindex');
      // // console.log(" titleDesignElements----------", titleDesignElements); // 输出 title
      // // 遍历所有 titleDesign 元素
      // titleDesignElements.forEach((element) => {
      //   // console.log("head1----------",'head'+element.id); // 输出 title
      //   // console.log("activted1----------",this.activeID1); // 输出 title
      //   const style = window.getComputedStyle(element);
      //   const rect = element.getBoundingClientRect();
      //   // 检查元素是否为 sticky 定位且顶部位置为 0（或页面顶部）
      //   if (style.position === 'sticky' && (rect.top < 150 && rect.top>0)) {
      //     this.activeID1 = element.id
      //   }
      // });
      let elementTop = this.$refs.zixun.offsetTop
      console.log('this.$refs.zixun.offsetTop---------',elementTop)
      let currentTop=document.getElementById("totalModel").scrollTop
      console.log('.getElementById("totalModel").scrollY--------',currentTop)
      if(currentTop+100 >= elementTop){
        let elementTop2 = this.$refs.general.offsetTop
        if (currentTop + 100 <= elementTop2){
          this.activeID1 = 'APP1'
        }else {
          this.activeID1 = 'APP2'
        }
      }
    },
    FreeMobileNavigation(refName){
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$refs[refName].getBoundingClientRect().top;
      console.log("distanceY---------",distanceY)
      // 使用 scrollIntoView 方法滚动到该元素s
      document.getElementById("totalModel").scrollBy({
        top: distanceY-80, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    },
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
  },
  mounted() {
    // this.titleDesignElements = document.querySelectorAll('.cardindex');
    // const throttledScroll = throttle(this.handleScroll2, 200);
    // document.getElementById("totalModel").addEventListener('scroll', throttledScroll);
    document.getElementById("totalModel").addEventListener('scroll', this.handleScroll2)
  },
  destroyed() {
    // 页面销毁时移除滚动事件监听
    document.getElementById("totalModel").removeEventListener('scroll', this.handleScroll2);
  }
}
</script>

<template>
  <div class="customizationGPTs" style="height: fit-content">
    <div class="customizationTitle">
      <span v-sliden-in="{duration:1200}" style="color: #371e74">
        1.免费油气资讯
      </span>
<!--      <div class="selectors" >-->
<!--        <div class="selector" id="setclass_APP1" :class="{'is-active': this.activeID1 === 'APP1'}" @click="FreeMobileNavigation('card1')">-->
<!--          <span>1. 油气行业资讯</span>-->
<!--        </div>-->
<!--        <div class="selector" id="setclass_APP2" :class="{'is-active': this.activeID1 === 'APP2'}" @click="FreeMobileNavigation('card2')">-->
<!--          <span>2. 办公辅助提效</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
      <div class="card" ref="card1" style="display: flex;height: fit-content">
<!--        <div style="width:20%;">-->
<!--          <div class="cardindex" key="key1" id="APP1" style="display: flex;z-index: 2;background-color: white;position: sticky;top:70px">-->
<!--         <span   style="margin: 20px auto ;font-weight: 700;font-size: 40px" >-->
<!--          1.-->
<!--         </span>-->
<!--          </div>-->
<!--        </div>-->

        <!--    油气资讯GPTss-->
        <div ref="zixun" class="screen13" style="background-color: transparent">
          <div style="top:45px;z-index: 2;background-color: white;padding-bottom: 5px">
            <div v-sliden-in="{duration:600}"  class="title" style="color:#371e76">&nbsp;免费油气资讯GPTs</div>
          </div>
            <div v-sliden-in="{duration:600}" class="light" style="margin:15px auto ;">
              免费为用户提供基于AI大模型技术的油气资讯服务。
              <span
                  class="light2" style="color: #193fe0"> <br>如需定制化的使用体验，请<span
                  style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('免费油气资讯GPTs')">点击咨询</span>。</span>

            </div>

          <div class="pro1">
            <!--采油气-->

            <!--                  <div v-sliden-in="{duration:600}"  @click="oilgpt1" >-->


            <div v-sliden-in="{duration:600}"  @click="info1" key="yqzx_002"  >

              <div class="grand1" style="position: relative;height: fit-content;padding:0" >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/information.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1" >油气知识 </div>
                  <div class="title2" >
                    更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:600}" @click="ToBigmodel" key="yqzx_001">
              <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <div class="grand1" style="position: relative;height: fit-content" >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/gptzixun.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> 免费油气资讯GPT</div>
                  <div class="title2" >
                    更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>
            </div>
            <div v-sliden-in="{duration:600}" @click="job1"  key="yqzx_003">
              <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
              <div class="grand1" style="position: relative;height: fit-content"  >
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/job.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> 油气人才</div>
                  <div class="title2" >
                    更多<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div >
      <div class="card" ref="card2" style="display: flex;height: fit-content">
<!--        <div style="width:20%;">-->
<!--          <div class="cardindex" key="key2" id="APP2" style="display: flex;z-index: 2;background-color: white;position: sticky;top:70px">-->
<!--        <span  style="margin: 20px auto ;font-weight: 700;font-size: 40px">-->
<!--          2.-->
<!--         </span>-->
<!--          </div>-->
<!--        </div>-->
        <!--    办公辅助提效GPTs-->
        <div ref="general" class="screenGeneral" style="background-color:white ;width: 100%">
          <!--            <div ref="general" style="display: flex;margin:0 auto 0 auto;background-color:white">-->
          <!--              <div style="display: flex;margin:0 auto;text-align: center">-->
          <!--                <div v-sliden-in="{duration:800}" class="title" style="color:#371e76">&nbsp;办公辅助提效GPTs</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <div style="top:45px;z-index: 2;background-color: white;padding-bottom: 5px">
            <div style="display:flex;">
                <span style="
                color:#371e75;
                margin: 0 auto;
                text-align: center;
                height: fit-content;
                opacity: 1;
                font-size: 20px;
                font-family: Roboto, sans-serif;
                font-weight: bold;
                line-height: 26px;" v-sliden-in="{duration:800}">
                  办公提效GPTs</span>
            </div>
          </div>
            <div v-sliden-in="{duration:800}" class="light">
              链接主流人工智能模型工具，让您一键触达。
              <span class="light2" style="color: #193fe0"><br>如您想基于主流GPTs开展定制化培训和开发，<br>请<span
                  style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('办公提效GPTs')">点击咨询</span>。</span>

            </div>

          <div class="pro1">
            <!--油田-->
            <div v-sliden-in="{duration:800}" key="ty1" class="grand2" @click="wen1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/wen.png"/>
                </div>
                <div>
                  <div class="title1">文心一言</div>

                </div>

              </div>
            </div>
            <!--        油藏-->
            <div v-sliden-in="{duration:1000}" key="ty2" class="grand2" @click="dou1">
              <div class="back">
                <div class="photo"><img loading="lazy" src="@/assets/dou.png"/></div>
                <div>
                  <div class="title1">豆包AI</div>

                </div>
              </div>
            </div>

            <!--        智能-->
            <div v-sliden-in="{duration:1200}" key="ty3" class="grand2" @click="tong1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/tong.png" style="height: 85%;margin-top: 7.5%"/>
                </div>
                <div>
                  <div class="title1">通义千问</div>

                </div>
              </div>
            </div>
            <!--采油气-->
            <div v-sliden-in="{duration:800}" key="ty4" class="grand2" @click="zhi1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zhi.png" style="height: 75%;margin-top: 10%"/>
                </div>
                <div>
                  <div class="title1">智谱清言</div>

                </div>
              </div>
            </div>

            <!--        智能-->
            <div v-sliden-in="{duration:1000}" key="ty5" class="grand2" @click="xun1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/xun.png" style="height:85%;margin-top: 5%"/>
                </div>
                <div>
                  <div class="title1">讯飞星火</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div v-sliden-in="{duration:1200}" key="ty6" class="grand2" @click="teng1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/teng.png" style="height: 90%;margin-top: 5%"/>
                </div>
                <div>
                  <div class="title1">腾讯混元</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div v-sliden-in="{duration:800}" key="ty7" class="grand2" @click="tian1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/tian.png"/>
                </div>
                <div>
                  <div class="title1">天工AI</div>

                </div>
              </div>
            </div>
            <!--采油气-->
            <div v-sliden-in="{duration:1200}" key="ty8" class="grand2" @click="ge1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/wen.png"/>
                </div>
                <div>
                  <div class="title1">文心一格</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div v-sliden-in="{duration:1200}" key="ty9" class="grand2" @click="wan1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/wan.png" style="height: 90%;margin-top: 5%"/>
                </div>
                <div>
                  <div class="title1">万彩AI</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <!--      <div class="grand2" @click="yi1">-->
            <!--        <div class="back">-->
            <!--          <div class="photo">-->
            <!--            <img loading="lazy" src="@/assets/yi.png" style="height: 85%;margin-top: 10%"/>-->
            <!--          </div>-->
            <!--          <div>-->
            <!--            <div class="title1">一帧秒创</div>-->

            <!--          </div>-->
            <!--        </div>-->
            <!--      </div>-->

          </div>

        </div>
      </div >
  </div>
</template>

<style scoped lang="scss">
.is-active{
  //color: red !important;
  background-color: #B4B4B4 !important;
}
.customizationGPTs {
  background-color: white;
  width: 100%;
  .customizationTitle{
    margin: 0 auto;

    text-align: center;
    //height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: 26px;
    position: sticky;
    top: -1px;
    padding:10px 0;
    background-color: white;
    z-index: 3;
    .selectors{
      margin-top:10px;
      margin-left: 2.5%;
      width: 95%;
      height: 40px;
      //background-color: #eb7c31;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      background-color: #371e75;
      //box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
      //border-bottom-left-radius: 10px;
      .selector{
        width: 100%;display: flex;
        //box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
        color: white;
      }
      span{
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        //height: -moz-fit-content;
        height: fit-content;
        opacity: 1;
        font-size: 20px;
        font-family: Roboto, sans-serif;
        font-weight: bold;
        line-height: 26px;

      }
    }
  }
  .screen13 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;


    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 0;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      //height: fit-content;
      width: 100%;
      //z-index: 89156;
      .swiper-container0{
        position: relative;
      }
      ::v-deep .swiper-pagination {
        position: absolute;
      }
      ::v-deep .swiper-pagination-bullet {
        width: 5px !important;
        height: 25px !important;

        //position: absolute;
      }

      .swiper-container-vertical > .swiper-pagination-bullets{
        left:0px !important;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(47, 110, 255);
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        margin:0 0 0 auto;
        border-radius: 6px;
        padding: 15px  0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }
  .screen13::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
  .screenGeneral {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 8px 4px 0 4px;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background:  white;;
          padding: 0 5px;
          //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
          border: 1px solid #C9C9C9;
          border-radius: 9px;
          //display: flex;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 8px auto 6px auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35px;
          height: 40px;
        }

        .title1 {
          opacity: 4;
          margin: 2px auto 8px auto;
          text-align: center;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          //font-weight: bold;
          color: #0a0a0a ;
          line-height: 25px;
        }

        .title2 {
          width: 110px;
          opacity: 4;
          margin: 5px 3px 5px 0;
          height: 14px;
          word-wrap: break-word; //换行
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }
    }

  }
  .screenGenera::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
}
</style>
