<template>
  <div class="image-container">
    <img src="@/assets/analysis2.png" class="masked-image" alt="Image with Blue Mask">
  </div>
</template>

<script>
export default {
  name: 'ImageWithBlueMask',
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 50%;
  height: 100%;
}

.masked-image {
  width: 100%;
  height: 100%;
  display: block;
}

/* 创建蓝色蒙版层 */
.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.5); /* 蓝色蒙版，透明度0.5 */
  pointer-events: none; /* 确保蒙版不会影响图像的点击事件 */
}
</style>
