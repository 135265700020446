<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="totalmakeHole">
        <div class="makeHole1">
          <div
              :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}"
              class="topback">
            <headtip @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
<!--          <div class="screen1">-->
<!--            -->
<!--            &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->

<!--            &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--            <div class="layer2">-->
<!--              <div v-sliden-instep="{duration:800}"  class="title1">-->
<!--                <img loading="lazy" src="@/assets/AnxunLogo.png">-->
<!--              </div>-->
<!--              <div v-sliden-instep="{duration:1600}" class="title11" style="font-weight: bolder;" >智能油气生产优化及AI资讯建设服务</div>-->
<!--              <div v-sliden-instep="{duration:2400}" class="title2" style="font-weight: bolder;">-->
<!--                提供全流程生产优化和智能决策支持，提升企业生产效率、降低运营成本-->
<!--              </div>-->
<!--              <div v-sliden-instep="{duration:3000}" class="title4" style="font-weight: bolder;">油气数智，简单易行</div>-->
<!--              <div class="titlle3" @click="clickConsult('油气通')" style="font-weight: bolder;">-->
<!--                产品咨询-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--            <div class="layer3"></div>-->
<!--          </div>-->
          <Anxunheader :title1="'智能油气生产优化及AI资讯建设服务'" :title2="'提供全流程生产优化和智能决策支持，提升企业生产效率、降低运营成本'" @clickConsult1="clickConsult('油气通')"></Anxunheader>
<!--          <div class="screenmake0">-->
<!--            <div class="heighLight">智能油气生产优化及AI资讯建设服务</div>-->
<!--            <div class="heightLight2">-->
<!--              智能油气生产优化及AI资讯建设服务依托人工智能、大数据分析和工业物联网等先进技术，提供<span style="color: rgb(255, 100, 0)">全流程生产优化和智能决策支持</span>，帮助企业<span style="color: rgb(255, 100, 0)">提升生产效率、降低运营成本，灵活应对多变的市场环境</span>，为决策者和技术人员提供精准的行业资讯，助力科学决策和持续创新。-->
<!--            </div>-->
<!--          </div>-->
          <div class="screenmake03" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight"> 您的收益</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 12vh">
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> 高效决策支持
                    </div>
                    <div class="title2">
                      显著缩短方案执行周期，加速见效
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">实时成本控制
                    </div>
                    <div class="title2">
                      动态调整，减少无效能耗，降低成本
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">全面优化方案
                    </div>
                    <div class="title2">
                      综合平衡多维度优化方案
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">减少人员投入
                    </div>
                    <div class="title2">
                      自动监控、控制生产过程，大幅节省现场服务人力资源
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/buildnew.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">多样化交互与迭代
                    </div>
                    <div class="title2">
                      满足不同用户需求，实现快速迭代与多专业支持
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake6">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
<!--            <div class="line"></div>-->
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">技术与业务融合难题
                    </div>
                    <div class="title2">
                      油气行业的专业性强，业务流程复杂，需要人工智能技术与行业知识深度结合，但目前算法工程师与业务人员之间存在沟通壁垒，难以形成有效的技术解决方案 。
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">智能化应用迭代升级挑战
                    </div>
                    <div class="title2">
                      油气行业的智能化应用需要持续的技术迭代和资金投入，但目前技术和方法尚不成熟，缺乏持续的升级改造和资金支持，导致智能化应用难以持续优化和扩展 。
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight"> 方案架构</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/build1.png"
                           style="border-radius: 9px" @click="showModalTrue(require('@/assets/build1.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--          <div class="screenmake02" style="background-color: transparent">-->
            <!--            <div class="heighLight">核心功能</div>-->
            <!--            <div class="grid">-->
            <!--              <div class="card">-->
            <!--                <div class="back" style="background-color: white">-->
            <!--                  <div class="titleDesign">-->
            <!--                    <div class="title2">-->
            <!--                      <li><span style="font-weight: bold">高效决策支持 </span><br>显著缩短方案执行周期，加速见效。</li>-->
            <!--                      <br>-->
            <!--                      <li><span style="font-weight: bold">实时成本控制 </span><br>动态调整，减少无效能耗，降低成本。-->
            <!--                      </li>-->
            <!--                      <br>-->
            <!--                      <li><span style="font-weight: bold"> 全面优化方案 </span><br>综合平衡多维度优化方案。-->
            <!--                      </li>-->
            <!--                      <br>-->
            <!--                      <li><span style="font-weight: bold">减少人员投入 </span><br>自动监控、控制生产过程，大幅节省现场服务人力资源-->
            <!--                        。-->
            <!--                      </li>-->
            <!--                      <br>-->
            <!--                      <li><span style="font-weight: bold">多样化交互与迭代 </span><br>满足不同用户需求，实现快速迭代与多专业支持。-->
            <!--                      </li>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="imgDesign">-->
            <!--                    <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">-->
            <!--                      <img loading="lazy" @click="showModalTrue(require('@/assets/build3.png'))"-->
            <!--                           style="border-top-right-radius: 9px;border-bottom-right-radius: 9px"-->
            <!--                           src="@/assets/build3.png">-->
            <!--                    </el-tooltip>-->
            <!--                  </div>-->
            <!--                </div>-->


            <!--              </div>-->
            <!--            </div>-->

            <!--          </div>-->
          </div>

          <div class="screenmake01" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight" >服务能力</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card" >
                <div class="back" style="background-color: white">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/build2.jpeg'))"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           src="@/assets/build2.jpeg">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">油气生产工艺智能优化服务 </span><br>通过部署全局优化系统与小场景智控产品，提升生产工艺的智能调整能力，优化生产过程。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">油气生产智能交互服务 </span><br>
                        通过实时分析生产经营数据和历史知识库，提供智能问答和解决方案推荐，增强生产环节的决策支持。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">油气生产智能设计施工服务 </span><br>结合石油工程的设计经验，实现智能化井位集输、管网、场站和工艺设计，促进设计质量和施工效率提升。
                      </li>
                      <li style="margin-top: 10px"><span
                          style="font-weight: bold">石油行业资讯AI平台建设服务 </span><br>为石油工程技术人员及油气领域管理和决策者，提供线上线下的石油经济、技术等领域标准/定制资讯服务。
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--          <div class="screenmake7">-->
            <!--            <div class="heighLight">-->
            <!--              核心功能-->
            <!--            </div>-->
            <!--            <div style="display: flex;">-->

            <!--              <div style="display: contents">-->
            <!--                <div class="grid" style="width: 34%;margin-top: 2vh;margin-left: 5vw">-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->

            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">-->
            <!--                          实时监控与数据采集-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过传感器实时监测关键参数并传输至中央系统处理-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">智能预警与故障诊断-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          利用AI识别异常，提前预警并提供故障诊断和维护建议-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">优化调度与资源管理-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          通过大数据优化气体调度，提升资源利用率，调整储气策略-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">远程控制与自动化操作-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          实现设备远程控制与自动化操作，减少人工干预，提高安全性-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="card">-->
            <!--                    <div class="back">-->
            <!--                      <div class="titleDesign">-->
            <!--                        <div class="title1">数据分析与决策支持-->
            <!--                        </div>-->
            <!--                        <div class="title2">-->
            <!--                          集成数据分析，提供运行报告和趋势分析，辅助科学决策-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="fanganimg" style="float: right;height: 100%;width:60% ">-->
            <!--                <img src="@/assets/equipment5new.png" style="float: right;    height: 77%;-->
            <!--    width: 90%;-->
            <!--    margin:3vh auto;">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div>-->
            <!--            &lt;!&ndash;            <div class="screenmake21">&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="heighLight"> 核心功能</div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu1.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">实时监控与数据采集&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过传感器实时监测关键参数并传输至中央系统处理。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu3.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">智能预警与故障诊断&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        利用AI识别异常，提前预警并提供故障诊断和维护建议。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" >&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;margin-top: 2.5px" src="../../assets/setingu4.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">优化调度与资源管理&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        通过大数据优化气体调度，提升资源利用率，调整储气策略。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              <div class="grid" style=" grid-template-columns:  minmax(0,1fr) minmax(0,1fr);padding: 0 28vh">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px" src="../../assets/setingu6.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">远程控制与自动化操作&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        实现设备远程控制与自动化操作，减少人工干预，提高安全性。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <div class="card">&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <div class="back" style="">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="imgDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <img loading="lazy"  style="border-radius: 9px;width: 40px" src="../../assets/setingu2.png">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <div class="titleDesign">&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title1">数据分析与决策支持&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                      <div class="title2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        集成数据分析，提供运行报告和趋势分析，辅助科学决策。&ndash;&gt;-->
            <!--            &lt;!&ndash;                      </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </div>&ndash;&gt;-->

            <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
            <!--          </div>-->
          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight"> 服务场景</div>
            <div v-sliden-in="{duration:800}" class="grid">

              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> 压缩机组运行
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>从设备能效、安全运行、成本优化等方面实现设备设施最优化运行参数获取；</li>
                      <li>大模型迭代，形成设备智控调优模型；</li>
                      <li>控制协议打通，实现机组起停及切换智能化控制。</li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build5.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">净化厂能耗
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>以水电气风油药等消耗数据为依托，结合工艺、生产、经营数据，分析用能薄弱环节；</li>
                      <li>通过大模型迭代，形成各类能耗管控场景化模型；</li>
                      <li>结合控制系统，下发最优运行参数，形成控制闭环。</li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build6.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">采气厂集输管网
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>指挥单井、管网、集输站依据选择的方案最优化运行；</li>
                      <li>实现智能调峰、调压；流量、压力智能分配；</li>
                      <li>阀室远程智能控制；增、减压系统能耗优化。</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div class="grid" style="margin-top: -0.212608vh;">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel"  ref="totalModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                智能油气生成优化
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                提供全流程生产优化和智能决策支持，提升企业生产效率、降低运营成本。
              </div>
<!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">油气数智，简单易行</div>-->
            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">智能油气生产优化及AI资讯建设服务</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">-->
<!--              智能油气生产优化及AI资讯建设服务依托人工智能、大数据分析和工业物联网等先进技术，提供<span style="color: rgb(255, 100, 0)">全流程生产优化和智能决策支持</span>，帮助企业<span style="color: rgb(255, 100, 0)">提升生产效率、降低运营成本，灵活应对多变的市场环境</span>，为决策者和技术人员提供精准的行业资讯，助力科学决策和持续创新。-->
<!--            </div>-->
<!--          </div>-->
          <div class="screenmake2" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight"> 技术特色</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign" >
                    <img loading="lazy" src="@/assets/guanwang1.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> 高效决策支持
                    </div>
                    <div class="title2">
                      显著缩短方案执行周期，加速见效
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang2.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">实时成本控制
                    </div>
                    <div class="title2">
                      动态调整，减少无效能耗，降低成本
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang3.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">全面优化方案
                    </div>
                    <div class="title2">
                      综合平衡多维度优化方案
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/guanwang4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">减少人员投入
                    </div>
                    <div class="title2">
                      自动监控、控制生产过程，大幅节省现场服务人力资源

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/buildnew.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">多样化交互与迭代
                    </div>
                    <div class="title2">
                      满足不同用户需求，实现快速迭代与多专业支持
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake8" style="padding-top: 0">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">技术与业务融合难题
                    </div>
                    <div class="title2">
                      油气行业的专业性强，业务流程复杂，需要人工智能技术与行业知识深度结合，但目前算法工程师与业务人员之间存在沟通壁垒，难以形成有效的技术解决方案 。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">.智能化应用迭代升级挑战
                    </div>
                    <div class="title2">
                      油气行业的智能化应用需要持续的技术迭代和资金投入，但目前技术和方法尚不成熟，缺乏持续的升级改造和资金支持，导致智能化应用难以持续优化和扩展 。
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight"> 方案架构</div>
            <div  class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                      <img loading="lazy" src="@/assets/build1.png"
                           style="border-radius: 9px" @click="showModalTrue(require('@/assets/build1.png'))">
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight" >服务能力</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="background-color: transparent">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/build2.jpeg'))"
                           style="border-top-left-radius: 9px;border-bottom-left-radius: 9px"
                           src="@/assets/build2.jpeg">
                    </el-tooltip>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">油气生产工艺智能优化服务 </span><br>通过部署全局优化系统与小场景智控产品，提升生产工艺的智能调整能力，优化生产过程。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">油气生产智能交互服务 </span><br>
                        通过实时分析生产经营数据和历史知识库，提供智能问答和解决方案推荐，增强生产环节的决策支持。
                      </li>
                      <li style="margin-top: 10px"><span style="font-weight: bold">油气生产智能设计施工服务 </span><br>结合石油工程的设计经验，实现智能化井位集输、管网、场站和工艺设计，促进设计质量和施工效率提升。
                      </li>
                      <li style="margin-top: 10px"><span
                          style="font-weight: bold">石油行业资讯AI平台建设服务 </span><br>为石油工程技术人员及油气领域管理和决策者，提供线上线下的石油经济、技术等领域标准/定制资讯服务。
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake3" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight"> 服务场景</div>
            <div class="grid">

              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build4.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> 压缩机组运行
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>从设备能效、安全运行、成本优化等方面实现设备设施最优化运行参数获取；</li>
                      <li>大模型迭代，形成设备智控调优模型；</li>
                      <li>控制协议打通，实现机组起停及切换智能化控制。</li>
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build5.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">净化厂能耗
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>以水电气风油药等消耗数据为依托，结合工艺、生产、经营数据，分析用能薄弱环节；</li>
                      <li>通过大模型迭代，形成各类能耗管控场景化模型；</li>
                      <li>结合控制系统，下发最优运行参数，形成控制闭环。</li>
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/build6.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">采气厂集输管网
                      智能调控脑
                    </div>
                    <div class="title2">
                      <li>指挥单井、管网、集输站依据选择的方案最优化运行；</li>
                      <li>实现智能调峰、调压；流量、压力智能分配；</li>
                      <li>阀室远程智能控制；增、减压系统能耗优化。</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--            <div class="screenmake4">-->
            <!--              <div class="heighLight">成功案例</div>-->
            <!--              <div class="grid">-->
            <!--                <el-carousel arrow="always" indicator-position="outside" interval="5000">-->
            <!--                  <el-carousel-item v-for="item in success" :key="item">-->
            <!--                    <div class="card">-->
            <!--                      <div class="back">-->
            <!--                        <div class="imgDesign">-->
            <!--                          <img :src="item.img" loading="lazy"/>-->
            <!--                        </div>-->
            <!--                        <div class="titleDesign">-->
            <!--                          <div class="title1">{{ item.title1 }}</div>-->
            <!--                          <div class="title2" v-html="item.title2"></div>-->
            <!--                        </div>-->
            <!--                      </div>-->


            <!--                    </div>-->


            <!--                  </el-carousel-item>-->
            <!--                </el-carousel>-->
            <!--              </div>-->

            <!--            </div>-->
          </div>
          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <el-carousel arrow="always" indicator-position="outside" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <el-tooltip class="item" content="点击放大，看得更清晰！" effect="dark" placement="top">
                          <img :src="item.img" loading="lazy" @click="showModalTrue(item.img)"/>
                        </el-tooltip>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";
import {vZoomIn} from '@/utils/vZoomIn.js'; // 确保路径正确
import {vSlidenInstep} from '@/utils/vSlidenInstep.js';
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vLeftIn} from "@/utils/vLeftIn";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",
  props: [],
  components: {Anxunheader,PartnerTip, consultTip, headtip, bottomTip, kefu, kefuMobile},
  data() {
    return {
      title: "",
      showModal: false,
      largeSrc: '',
      pageTitle: '智能油气生产优化及AI资讯建设服务_安迅数智-油气通GPT',
      pageDescription: ' ？？？？',
      pageKeywords: '智慧储气库解决方案，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',
      scrollback: false,
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/buildsucesse.png"),
        title1: "智能油气生产优化及AI资讯建设服务\n",
        title2: "<li>某井下作业技术研发现状资讯服务\n</li>",
      },
      ]
    }

  },
  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },    showModalTrue(item) {
      this.showModal = true;
      console.log(item)
      this.largeSrc = item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 500) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智慧安防解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
        pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }

    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 63vh;
    //  padding: 0 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //
    //  .layer2 {
    //    position: absolute;
    //    top: 12vh;
    //    left: 9.17157712305026vw;
    //    .title11{
    //      margin: 3.8207663782vh auto;
    //      font-size: 2vw;
    //    }
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      //font-size: 3.50606585788562vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      //line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      //margin-top: 4vh;
    //      width: 33.45869439630272vw;
    //      height: fit-content;
    //      font-size: 1.0597920277vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: black;
    //      line-height: 4.31520395550062vh;
    //    }
    //
    //    .title4 {
    //      height: 8.77626699629172vh;
    //      font-size: 1.6060658579vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //
    //    .titlle3 {
    //      margin-top: 2.07911001236094vh;
    //      cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //
    //    }
    //
    //    .titlle3:hover {
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}

    .screenmake0 {
      padding: 5.78739184177998vh 8.09763142692085vw 0 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 82vw;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
    }

    .screenmake01 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 55vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake02 {
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 0.5vw;
        text-align: left;
        height: fit-content;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .heightLight3 {
        margin: 3vh auto;
        text-align: center;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 59vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: auto 1vw auto auto;
              color: black;
              width: 50%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                line-height: 3.3vh;
                width: fit-content;
                margin: auto;
                font-size: 0.9vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 32vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 0 2.5248454883vw 2.5248454883vh 2.5248454883vw;
              margin: 0 auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 1vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake03{
      padding: 5.78739184177998vh 8.09763142692085vw ;
      width: 100%;
      height: fit-content;
      background: transparent;
      //background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #eff5ff;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 39vh;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                text-align: center;
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake6 {
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:21vh;
        z-index: 1;
      }
      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:   minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 2vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height: 8vh;
              margin:0 auto 2vh auto;
              background-color: white;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              width: 100%;
              //margin: 0 0 0 1.5vw;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1 {
      padding: 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 5.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake5 {
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: white;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 38vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              margin: 0 auto 1vh auto;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;

              .title1 {
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      padding:  5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      //background: transparent;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: 100%;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 100%;
            overflow: hidden;

            .imgDesign {
              width: 14vh;
              height: 16vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1 {
                text-align: center;
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 3vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                height: fit-content;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }
            }
          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake21 {
      padding: 5.78739184177998vh 13.097631vw 4.78739184177998vh 13.097631vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 15vh;
            overflow: hidden;

            .imgDesign {
              width: 5vh;
              height: 5vh;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 0 0 1.5vw;
              color: black;
              width: 85%;
              float: right;

              .title1 {
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake7 {
      padding: 5.78739184177998vh 13.097631vw 4.78739184177998vh 13.097631vw;
      width: 100%;
      //height: 78vh;
      //height: 50vh;
      height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        position: relative;
        //display: grid;
        //grid-template-columns:  minmax(0, 1fr);
        //column-gap: 1vh;
        //row-gap: 1vh;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0.5vh auto;
              color: black;
              width: 100%;
              float: right;

              .title1 {
                margin: 0 1vh;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 0;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                text-align: left;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake3 {
      padding: 0vh 7vw;
      width: 100%;
      height: 78vh;
      //height: 50vh;
      //height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 5.787392vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr);
        grid-template-rows: 9vh 14vh 14vh 14vh;
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: rgba(0, 0, 0, 0);
            border-radius: 9px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: fit-content;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 3vh auto;
              color: black;
              height: 20vh;
              width: 100%;
              float: right;

              .title1 {
                margin: 0 auto;
                text-align: left;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin: 2vh 0;
                width: 69vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //float: right;
          //cursor: pointer;
          width: 90%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 50vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 0;
              margin: auto;
              text-align: center;
              color: white;
              width: 30%;
              float: right;

              .title1 {
                text-align: center;
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 52vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        left: 9.17157712305026vw;

        .title1 {
          width: 200px;;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        //position: absolute;
        //bottom: 30px;
        //left: 50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenmake0 {
      //z-index:: 10;
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw 0 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        padding: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

    }
    .screenmake8{
      width: 100vw;
      height: fit-content;
      padding:20px  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1 {
      width: 100vw;
      height: fit-content;
      padding: 5px 4.6875vw 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .titleDesign {
            margin-top: 20px;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height: fit-content;
            margin-top: 15px;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              //border: #666666 1px solid;
              height: fit-content;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake2 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: transparent;
      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0, 1fr);
        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .title3 {
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
            width: 80%;
          }

          .back {
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px;
            height: fit-content;
            overflow: hidden;
            display: flex;

            .imgDesign {
              width: 45px;
              height: 45px;
              margin: auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake3 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 10px;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 15px auto;
            //width: 45vh;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin: 10px 10px;
            padding: 0 15px;
          }

          .back {
            margin-top: 10px;
            background-color: #ebf1fd;
            padding: 0 0 10px 0;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              margin: 15px auto 0 auto;
              width: 85px;
              height:85px;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: fit-content;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;

            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 300px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -55px -10px;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .screenmake6 {
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: transparent;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid {
        position: relative;
        height: fit-content;

        .card {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .title1 {
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            margin: 5px 20px;
          }

          .title2 {

            width: auto;
            margin: 0px 25px;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 20px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            height: fit-content;
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 100%;
              height: 195px;
              opacity: 1;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }

      .grid1 {
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 25px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;
        row-gap: 15px;

        .card {
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height: 185px;
          background-color: white;
          padding: 5px 10px;
          opacity: 1;

          .title1 {
            height: 45px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
          }

          .title2 {
            margin: 0 5px;
            text-align: justify;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign {
            margin-top: 10px;
          }

          .title3 {
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            line-height: 22px;
            font-size: 14px;
          }

          .back {
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow: hidden;

            .imgDesign {
              width: 40px;
              height: 38px;
              margin: 0 10px 0 5px;
              opacity: 1;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
