<template>

  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
          <div class="topback"  :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed'}">
            <headtip  :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
          </div>
          <kefu @toptop="topTop"></kefu>
          <div @click="changeheadVisable">
<!--            <div class="screen1">-->

<!--              &lt;!&ndash;      logo  you气通GPT社区 合作伙伴  登录&ndash;&gt;-->

<!--              &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--              <div class="layer2">-->
<!--                <div v-sliden-instep="{duration:800}"  class="title1">-->
<!--                  <img loading="lazy" src="@/assets/AnxunLogo.png">-->
<!--                </div>-->

<!--                <div v-sliden-instep="{duration:1600}" class="title11" style="font-weight: bolder;" >智慧安防解决方案</div>-->
<!--                <div v-sliden-instep="{duration:2400}" class="title2" style="font-weight: bolder">打造全天候综合安防体系，降低运维成本，保障生产安全。</div>-->
<!--                <div v-sliden-instep="{duration:3000}" class="title4" style="font-weight: bolder">油气数智，简单易行</div>-->
<!--                <div class="titlle3" style="font-weight: bolder" @click="clickConsult('油气通')">-->
<!--                  产品咨询-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--              <div class="layer3"></div>-->
<!--            </div>-->
            <Anxunheader :title1="'智慧安防解决方案'" :title2="'打造全天候综合安防体系，降低运维成本，保障生产安全。'" @clickConsult1="clickConsult('油气通')"></Anxunheader>
<!--            <div class="screenmake0">-->
<!--              <div class="heighLight">智慧安防解决方案</div>-->
<!--              <div class="heightLight2">  </div>-->
<!--            </div>-->
            <div class="screenmake3" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight"> 您的收益</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign" style="width: 100%;height: 30vh">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new3.png">

                    </div>
                    <div class="titleDesign" style="padding-bottom: 2vh">
                      <div class="title1">显著降低安全风险
                      </div>
                      <div class="title2">
                        <li>AI识别风险，提升安防主动性；</li>
                        <li> 人车快速通行，减少无效等待；</li>
                        <li>快速锁定目标，减少清场时间；</li>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign" style="width: 100%;height: 30vh">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new2.png">

                    </div>
                    <div class="titleDesign" style="padding-bottom: 2vh">
                      <div class="title1">全面提升人员劳效
                      </div>
                      <div class="title2">
                        <li>实时监测设备和系统运行状态；</li>
                        <li>一屏掌握安防情况，并生成报告；</li>
                        <li>安防事件响应时间缩短达80%；</li>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back" style="background-color: white">
                    <div class="imgDesign" style="width: 100%;height: 30vh">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new1.png">
                    </div>
                    <div class="titleDesign" style="padding-bottom: 3vh">
                      <div class="title1">
                        系统灵活迭代可持发展
                      </div>
                      <div class="title2">
                        <li>数据整合，全面安防管理；</li>
                        <li>新系统快速接入，统一管理；</li>
                        <li>流程可扩展，灵活适应业务；</li>
                        <li>完整业务平台，功能可定制；</li>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake3">
              <div v-sliden-instep="{duration:3000}" class="heighLight">   业务痛点与挑战</div>
<!--              <div class="line"></div>-->
              <div class="grid">
                <div v-sliden-instep="{duration:3000}" class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;  ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/security01.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">
                        监控盲区与误报
                      </div>
                      <div class="title2">
                        存在监控死角，导致重要区域无法得到有效监控。
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-instep="{duration:3000}" class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;  ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/security02.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">智能化水平低
                      </div>
                      <div class="title2">
                        依赖大量人力进行巡逻和检查，效率低下且易出错；只能提供简单的视频监控功能，缺乏智能分析能力。
                      </div>
                    </div>
                  </div>


                </div>
                <div v-sliden-instep="{duration:3000}" class="card">
                  <div class="back">
                    <div class="imgDesign" style="width: fit-content;margin:0 auto 0 auto;  ">
                      <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/security03.png">

                    </div>
                    <div class="titleDesign">
                      <div class="title1">应急响应慢
                      </div>
                      <div class="title2">
                        传统安防系统在发现异常情况后，往往需要较长时间才能做出响应。
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="screenmake1" style="background-color: transparent">
              <div v-sliden-in="{duration:800}" class="heighLight">  方案架构</div>
              <div class="grid">
                <div v-sliden-in="{duration:800}" class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                        <img loading="lazy" @click="showModalTrue(require('@/assets/anxun21new.png'))" style="border-radius: 9px" src="@/assets/anxun21new.png">

                      </el-tooltip>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="screenmake2" style="background-color: white">
              <div v-sliden-in="{duration:800}" class="heighLight">   技术特色</div>
              <div v-sliden-in="{duration:800}" class="grid">
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu01.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">AI智能远程监控识别
                      </div>
                      <div class="title2">
                        利用AI远程监控技术进行精准实时监控与智能识别，自动检测并报警潜在风险，随时查看现场情况，提高安全防范和管理效率。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu02.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">实时智能报警系统
                      </div>
                      <div class="title2">
                        集成高效报警机制，异常情况时立即通知相关人员，确保快速响应和处理。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu06.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">自动化智能巡检
                      </div>
                      <div class="title2">
                        自动化巡检系统定期检查设备和设施，及时发现故障和隐患，确保设备安全运行。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">QHSE综合管理平台
                      </div>
                      <div class="title2">
                        实现安全风险监控、跟踪、统计和多维分析，智能生成风险预警信息并推送解决方案，进行上报处理，提高风险处理的效率和准确性。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu07.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">精准人员定位系统
                      </div>
                      <div class="title2">
                        利用定位技术实时跟踪人员位置，提供紧急情况下的快速救援支持。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.svg">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">智能化消防系统
                      </div>
                      <div class="title2">
                        集成智能消防设备和机器人，实现快速灭火和高效救援，提升火灾应对能力。
                      </div>
                    </div>
                  </div>


                </div>
                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun7.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">无人机防御系统
                      </div>
                      <div class="title2">
                        采用无人机反制技术，有效防止非法飞行器入侵，保障油田安全。
                      </div>
                    </div>
                  </div>


                </div>

                <div class="card">
                  <div class="back">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu08.png">
                    </div>
                    <div class="titleDesign">
                      <div class="title1">VR安全培训与模拟
                      </div>
                      <div class="title2">
                        通过VR技术进行安全培训和应急演练，增强人员安全意识和应急处置能力。
                      </div>
                    </div>
                  </div>


                </div>

              </div>

            </div>

            <div class="screenmake4">
              <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
              <div class="grid">
                <el-carousel indicator-position="outside" arrow="always" interval="5000">
                  <el-carousel-item v-for="item in success" :key="item">
                    <div class="card">
                      <div class="back">
                        <div v-left-in="{duration:800}" class="imgDesign">
                          <el-tooltip class="item" effect="dark" content="点击放大，看得更清晰！" placement="top">
                            <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>

                          </el-tooltip>  </div>
                        <div v-sliden-in="{duration:800}" class="titleDesign">
                          <div class="title1">{{item.title1}}</div>
                          <div class="title2" v-html="item.title2"></div>
                        </div>
                      </div>


                    </div>


                  </el-carousel-item>
                </el-carousel>
              </div>

            </div>
            <bottomTip></bottomTip></div>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content" >
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel"  ref="totalModel">
        <div class="makeHole1">
          <div class="screen1">
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-sliden-instep="{duration:800}" class="title1"><img loading="lazy" src="@/assets/AnxunLogo.png"></div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;font-size: 25px;margin-top: 15px;line-height: 30px;color:#F44336">
                智慧安防
              </div>
              <div v-sliden-instep="{duration:1600}" class="title2" style="font-weight: bolder;margin-top: 15px;font-size: 18px;line-height: 28px;color:#2c3e50;text-align: left">
                打造全天候综合安防体系，降低运维成本，保障生产安全。</div>
<!--              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;">-->
<!--                油气数智，简单易行</div>-->

            </div>
            <div style="position: absolute;bottom: 30px;left: 50%;transform:translateX(-50%);">
              <div v-sliden-instep="{duration:2400}" class="title4" style="font-weight: bolder;font-size: 18px;line-height: 28px;padding-bottom: 5px;color: #2f6dfd;">油气数智，简单易行</div>
              <div class="titlle3" @click="clickConsult('油气通')">
                产品咨询
              </div>
            </div>

            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">智慧安防解决方案</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">随着智能时代的到来，油田安全监控需求不断提升。安迅数智智慧安防解决方案从油田QHSE管理角度出发，<span style="color: rgb(255, 100, 0)">专注于油田安防场景，打造技防代替人防、通行便捷高效、应急处置及时、主动实时防范的综合安防系统</span>。<br>安迅数智提供全面的管理支持与安全控制，确保安全管理更加便捷、精准、安全，响应更加及时。</div>-->
<!--          </div>-->
          <div class="screenmake3" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">您的收益</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new3.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">显著降低安全风险
                    </div>
                    <div class="title2">
                      <li>AI识别风险，提升安防主动性；</li>
                      <li> 人车快速通行，减少无效等待；</li>
                      <li>快速锁定目标，减少清场时间；</li>
                    </div>
                  </div>
                </div>
              </div>

              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new2.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">全面提升人员劳效
                    </div>
                    <div class="title2">
                      <li>实时监测设备和系统运行状态；</li>
                      <li>一屏掌握安防情况，并生成报告；</li>
                      <li>安防事件响应时间缩短达80%；</li>
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      系统灵活迭代可持发展
                    </div>
                    <div class="title2">
                      <li>数据整合，全面安防管理；</li>
                      <li>新系统快速接入，统一管理；</li>
                      <li>流程可扩展，灵活适应业务；</li>
                      <li>完整业务平台，功能可定制；</li>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake5" style="padding-top: 15px">
            <div v-sliden-in="{duration:800}" class="heighLight"> 业务痛点与挑战</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">监控盲区与误报
                    </div>
                    <div class="title2">
                      存在监控死角，导致重要区域无法得到有效监控。
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">智能化水平低
                    </div>
                    <div class="title2">
                      依赖大量人力进行巡逻和检查，效率低下且易出错；只能提供简单的视频监控功能，缺乏智能分析能力。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/security03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">应急响应慢
                    </div>
                    <div class="title2">
                      传统安防系统在发现异常情况后，往往需要较长时间才能做出响应。
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">  方案架构</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun21new.png">

                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake2" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">   技术特色</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu01.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">AI智能远程监控识别
                    </div>
                    <div class="title2">
                      利用AI远程监控技术进行精准实时监控与智能识别，自动检测并报警潜在风险，随时查看现场情况，提高安全防范和管理效率。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu04.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">QHSE综合管理平台
                    </div>
                    <div class="title2">
                      实现安全风险监控、跟踪、统计和多维分析，智能生成风险预警信息并推送解决方案，进行上报处理，提高风险处理的效率和准确性。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back"  style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;height: 40px;margin-top: 2.5px" src="@/assets/anxunu02.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">实时智能报警系统
                    </div>
                    <div class="title2">
                      集成高效报警机制，异常情况时立即通知相关人员，确保快速响应和处理。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu06.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">自动化智能巡检
                    </div>
                    <div class="title2">
                      自动化巡检系统定期检查设备和设施，及时发现故障和隐患，确保设备安全运行。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;width: 40px" src="@/assets/anxunu04.svg">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">智能化消防系统
                    </div>
                    <div class="title2">
                      集成智能消防设备和机器人，实现快速灭火和高效救援，提升火灾应对能力。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu07.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">精准人员定位系统
                    </div>
                    <div class="title2">
                      利用定位技术实时跟踪人员位置，提供紧急情况下的快速救援支持。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxun7.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">无人机防御系统
                    </div>
                    <div class="title2">
                      采用无人机反制技术，有效防止非法飞行器入侵，保障油田安全。
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 220px">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/anxunu08.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">VR安全培训与模拟
                    </div>
                    <div class="title2">
                      通过VR技术进行安全培训和应急演练，增强人员安全意识和应急处置能力。
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">成功案例</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefuMobile from "@/views/kefuMobile.vue";
import kefu from "@/views/kefu.vue";
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vZoomIn} from "@/utils/vZoomIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import {vLeftIn} from "@/utils/vLeftIn";
import Anxunheader from "@/views/Anxun/components/Anxunheader.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
    leftIn:vLeftIn
  },
  name: "",

  props: [],

  components: {Anxunheader, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {
    return {
      changeVisable:1,
      title:"",
      showModal: false,
      largeSrc:'',
      pageTitle: '智慧安防解决方案_安迅数智-油气通GPT',
      pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
      pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',
      scrollback:false,
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/anxun22.png"),
        title1:"智慧安防",
        title2:"<li>国内某增压脱烃站智慧安防项目</li><li>伊拉克某油田智慧安防建设项目</li>",
      },{
        img:require("@/assets/anxun23.png"),
        title1:"智慧安防",
        title2:"<li>国内某增压脱烃站智慧安防项目</li><li>伊拉克某油田智慧安防建设项目</li>",
      },
      ]
    }

  },


  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },
  },
  beforeDestroy() {

    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '智慧安防解决方案_安迅数智-油气通GPT',
        pageDescription: '安迅数智智慧安防解决方案，基于油田HSSE管理角度设计，提供全方位管理支持和全面安全保障，包括特种设备管理、特殊作业管理、承包商管理；全面安全控制：安全教育、风险分级管控、 隐患排查治理、重大危险源管理、重点现场监控、危化品监管。',
        pageKeywords: '智慧安防，风险排查与隐患治理，消防机器人，特殊作业管控，精确人员定位平台，智能 AI 安防，飞行器反制，智能AI巡检，智慧培训与应急模拟演练，智能消防，安迅数智，油气通GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
 .topback{
    position: fixed;
    z-index:999;
      width: 100vw;
      padding: 0 0 0 2.86077411900635vw;
    }
    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 70vh;
    //  padding: 0 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //
    //  .layer2 {
    //    position: absolute;
    //    top: 16.1495673671199vh;
    //    left: 9.17157712305026vw;
    //    .title11{
    //      margin: 3.8207663782vh auto 3.0207663782vh auto ;
    //      font-size: 2vw;
    //
    //    }
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      //font-size: 3.50606585788562vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      //line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      //margin-top: 6.82076637824475vh;
    //      width: 33.45869439630272vw;
    //      height: fit-content;
    //      font-size: 1.0597920277vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: black;
    //      line-height: 4.31520395550062vh;
    //    }
    //    .title4{
    //      height: 8.77626699629172vh;
    //      font-size: 1.6060658579vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //    .titlle3{
    //      margin-top: 2.07911001236094vh;
    //      cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: Sarial, sans-serif, "Microsoft Yahei";
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //    }
    //    .titlle3:hover{
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}
    .screenmake0{
      padding: 5.78739184177998vh 8.09763142692085vw  0  8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 82vw;
        font-size: 1.15vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
    }
    .screenmake1{
      padding: 7vh 11.09763142692085vw 5.78739184177998vh 11.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:5.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      padding: 5.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:20vh ;
            overflow:hidden;
            .imgDesign{
              width: 5vh;
              height:5vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              color: black;
              width: 85%;
              float: right;
              .title1{
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      padding: 5.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:20vh;
        z-index: 1;

      }
      .grid{
        z-index: 2;
        margin-top:0vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 4vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:100% ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #c9d9f5;
            border-radius: 9px;
            position: relative;
            //border: white 1px solid;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:100%;
            overflow:hidden;
            .imgDesign{
              width:8vh;
              height:8vh;
              background-color: white;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:2vh auto 0 auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 0 auto;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin: 2vh auto 0 auto;
                width: fit-content;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      padding: 5.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:58vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 1.5248454883vw;
              margin:auto;
              text-align: center;
              color: white;
              width: fit-content;
              float: right;
              .title1{
                text-align: left;
                font-size: 1.1709416522vw;
              }
              .title2{
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:60vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 600px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        left: 9.17157712305026vw;

        .title1 {
          width:200px;
        ;
          font-size: 3.50606585788562vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 5px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        //position: absolute;
        //bottom:30px;
        //left:50%;
        //transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: auto;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw 0 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        padding:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

    }
    .screenmake5{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .titleDesign{
            margin-top: 20px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:fit-content;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            background: #ebf1fd;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 15px 10px ;
            height:255px;
            margin-top: 5px;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top: 10px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin: 15px auto;
            width: fit-content;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign{

            margin:10px 10px;
          }
          .back{
            margin-top: 10px;
            background-color: white;
            padding: 0 0  10px 0;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:194px;
              opacity: 1;

            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 340px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -65px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
